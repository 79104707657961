import PropTypes from 'prop-types';


handleSelectClick.prototype = {
    setSelected: PropTypes.func,
    selected: PropTypes.array,
    ident: PropTypes.string || PropTypes.number,
}

export function handleSelectClick (ident, selected, setSelected)  {
    const selectedIndex = selected.indexOf(ident);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, ident);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
};