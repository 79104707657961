/* eslint-disable import/order */
/* eslint-disable camelcase */
import { sentenceCase } from 'change-case';
import { useState, useCallback, useEffect } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import {
  Card,
  Avatar,
  Button,
  Container,
  Typography,
  Box,
  Select,
  InputLabel,
  FormControl,
  MenuItem,
  TextField,
  IconButton,
  Skeleton
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
// routes
import axios, { getErrorMessage } from 'utils/axios';
import useIsMountedRef from 'hooks/useIsMountedRef';
import CRUD from 'utils/Crud';
import { fDate, fDateTime } from 'utils/formatTime';
import { PATH_DASHBOARD } from '../../routes/paths';
// hooks
import useSettings from '../../hooks/useSettings';

// components
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
// sections
import { DataGrid, } from '@mui/x-data-grid';
import { LoadingButton } from '@mui/lab';
import SearchQueryDailog from 'sections/@dashboard/SearchQueryDailog';
import { useSnackbar } from 'notistack';
import { fNumber } from 'utils/formatNumber';
import Label from 'components/Label';
import OrderDialog from 'sections/@dashboard/order/OrderDialog';
import CustomGridToolbar from 'components/CustomGridToolbar';
import useAuth from 'hooks/useAuth';
import ProfileDialog from 'sections/@dashboard/user/ProfileDialog';
import OrderCreateModal from 'sections/@dashboard/order/OrderCreateModal';
// ----------------------------------------------------------------------



export default function ManageOrders() {
  const theme = useTheme();
  const { themeStretch } = useSettings();
  const isMountedRef = useIsMountedRef();
  const [sort, setSort] = useState({ field: 'created_at', order: 'desc', status: 'submitted', transaction_type: '', staff_id: '', user_id: '', start_date: '', end_date: '' });
  const [data, setData] = useState({ data: [], current_page: 1, total_pages: null, total_size: 0, page_size: 0 });
  const [state, setState] = useState({ loading: false, loadComplete: false, error: null });
  const [query, setQuery] = useState({ page_size: 50, page: 0 });
  const [dailog, setDailog] = useState({ open: false, query: "", });
  const [dailog2, setDailog2] = useState({ open: false });
  const [orderDialog, setOrderDialog] = useState({ open: false, user: "", });
  const [search, setSearch] = useState({ open: false, query: "", list: [] });
  const [stafState, setStafState] = useState({ loading: true, loadComplete: false, error: null, list: [] });
  const [profileDialog, setProfileDialog] = useState({ open: false, user: "", });
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuth();


  useEffect(() => {
    getInitData();
    getStaffs();
  }, []);

  // const updateData = (k, v) => setData((prev) => ({ ...prev, [k]: v }));

  return (
    <Page title="Manage order">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="Orders"
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            { name: 'Orders', href: PATH_DASHBOARD.root.list },
          ]}
          action={
            <>
              <LoadingButton loading={state.loading} onClick={() => getInitData()}
                sx={{ mr: 2 }} variant='outlined' color="secondary">
                Apply changes
              </LoadingButton>
              <Button
                variant="outlined"
                component={Button}
                onClick={() => {
                  setDailog({ open: true, isEdit: false });
                }}
                startIcon={<Iconify icon={'cil:search'} />}
              >
                Find order
              </Button>
              <Button  sx={{ ml: 2 }} 
                variant="contained"
                component={Button}
                onClick={() => {
                  setDailog2({ open: true });
                }}
                startIcon={<Iconify icon={'cil:search'} />}
              >
                Create order
              </Button>
            </>
          }
        />

        {!state.loading && state.error != null && (
          <Card>
            <Box padding={10} display={"flex"} justifyContent={"center"}>
              <p>Error {state.error} </p>
            </Box>
          </Card>
        )}

        <Card>
          {search.open &&
            <Box sx={{ display: "flex", alignContent: "right", justifyContent: "space-between", width: "100%", padding: 2 }}>
              <p>Showing results for: {search.query}</p>
              <LoadingButton sx={{ mr: 2 }}
                startIcon={<Iconify icon={'carbon:close-filled'} />}
                loading={state.loading}
                onClick={() => setSearch({ ...search, open: false })}
                variant='outlined' color="error">
                Close search
              </LoadingButton>
            </Box>}
          {!search.open &&
            <Box sx={{ display: "flex", alignContent: "right", justifyContent: "end", width: "100%", padding: 2 }}>
              <FormControl sx={{ ml: 2, width: 180 }}>
                <InputLabel>Transactions type</InputLabel>
                <Select
                  label="Transactions type"
                  value={sort.transaction_type}
                  onChange={(e) => setSort({ ...sort, transaction_type: e.target.value })}
                >
                  {[
                    { v: '', n: "All transactions" },
                    { v: 'buy', n: "Buy" },
                    { v: 'sell', n: "Sell" },
                  ].map((opt, i) => (
                    <MenuItem key={i} value={opt.v}>{opt.n}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ ml: 2, width: 180 }}>
                <InputLabel>Status</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  label="Status"
                  value={sort.status}
                  onChange={(e) => setSort({ ...sort, status: e.target.value })}
                >
                  {[
                    { v: 'created', n: "Created" },
                    { v: 'cancelled', n: "Cancelled" },
                    { v: 'submitted', n: "Submitted" },
                    { v: 'processing', n: "Processing" },
                    { v: 'completed', n: "Completed" },
                  ].map((opt, i) => (
                    <MenuItem key={i} value={opt.v}>{opt.n}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ ml: 2, width: 180 }}>
                <InputLabel id="demo-simple-select-label">Sort field</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  label="Sort field"
                  value={sort.field}
                  onChange={(e) => setSort({ ...sort, field: e.target.value })}
                >
                  {[
                    { v: 'created_at', n: "Created at" },
                    { v: 'amount ', n: "Amount" },
                    { v: 'total_quantity', n: "Total quantity" },
                    { v: 'settlement_amount', n: "Settlement amount" },
                  ].map((opt, i) => (
                    <MenuItem key={i} value={opt.v}>{opt.n}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ ml: 2, width: 180 }}>
                <InputLabel id="demo-simple-select-label2">Sort order</InputLabel>
                <Select
                  labelId="demo-simple-select-label2"
                  label="Sort order"
                  value={sort.order}
                  onChange={(e) => setSort({ ...sort, order: e.target.value })}
                >
                  {[
                    { v: 'asc', n: "Ascending" },
                    { v: 'desc', n: "Descending" },
                  ].map((opt, i) => (
                    <MenuItem key={i} value={opt.v}>{opt.n}</MenuItem>
                  ))}
                </Select>
              </FormControl>

            </Box>}
          {!search.open &&
            <Box sx={{ display: "flex", alignContent: "right", justifyContent: "end", width: "100%", padding: 2 }}>
              {stafState.loading && (
                <Skeleton variant="rectangular" sx={{ width: 180 }} height={50} />
              )}
              {stafState.loadComplete && (
                <>
                  <FormControl sx={{ width: 180 }}>
                    <InputLabel id="demo-simple-select-label">Assigned to</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={sort.staff_id}
                      label="Assigned to"
                      onChange={(e) => setSort({ ...sort, staff_id: e.target.value })}
                    >
                      <MenuItem value="">All</MenuItem>
                      {stafState.list.filter((el) => el.status === "active").map((option) => (
                        <MenuItem key={option.staff_id} value={option.staff_id}>
                          {option.first_name} {option.last_name} ({option.role})
                        </MenuItem>))}
                    </Select>
                  </FormControl>
                </>
              )}
              <FormControl sx={{ width: 180, ml: 2 }}>
                <TextField id="user_id_field"
                  variant='outlined'
                  value={sort.user_id}
                  onChange={(e) => setSort({ ...sort, user_id: e.target.value })}
                  label="User I.D" />
              </FormControl>
              <FormControl sx={{ ml: 2, width: 180 }}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    value={sort.start_date}
                    label="From date"
                    onChange={(newValue) => setSort({ ...sort, start_date: newValue })}
                  />
                </LocalizationProvider>
              </FormControl>
              <FormControl sx={{ ml: 2, width: 180 }}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    value={sort.end_date}
                    label="To date"
                    onChange={(newValue) => setSort({ ...sort, end_date: newValue })}
                  />
                </LocalizationProvider>
              </FormControl>

            </Box>}

          <Box sx={{ height: 700, width: '100%' }}>
            <DataGrid
              rows={search.open ? search.list : data.data}
              columns={[
                {
                  field: 'name',
                  headerName: 'Name',
                  width: 220,
                  editable: false,
                  renderCell: (params) => (
                    < >
                      <Avatar alt={params.value} src={params.row.image_url} sx={{ mr: 2 }} />
                      <Typography variant="subtitle2" sx={{ cursor: "pointer" }}>
                        {params.row.name} ({params.row.coin_id})
                      </Typography>
                    </>
                  ),
                  valueGetter: (params) => `${params.row.name}(${params.row.coin_id})`
                },
                {
                  field: 'order_id',
                  headerName: 'Order I.D',
                  width: 180,
                  editable: false,
                  valueGetter: (params) => params.value,
                  renderCell: (params) => (
                    <p>
                      <IconButton color="primary" onClick={() => setOrderDialog({ open: true, order: params.row })}>
                        <Iconify icon={'ion:open-outline'} size="small" sx={{ width: 25, height: 25 }} />
                      </IconButton>
                      #{params.value}
                    </p>
                  ),
                },
                {
                  field: 'user',
                  headerName: 'User',
                  width: 180,
                  editable: false,
                  valueGetter: (params) => `${params.row.first_name} ${params.row.last_name}`,
                  renderCell: (params) => (
                    <p>
                      <IconButton color="primary" onClick={() => setProfileDialog({ open: true, user: params.row })}>
                        <Iconify icon={'ph:user-thin'} size="small" sx={{ width: 25, height: 25 }} />
                      </IconButton>
                      {params.row.first_name} {params.row.last_name}
                    </p>
                  ),
                },
                {
                  field: 'amount',
                  headerName: 'Amount (USD)',
                  width: 180,
                  editable: false,
                  valueGetter: (params) => `$${fNumber(params.row.amount)}`
                },
                {
                  field: 'status',
                  headerName: 'Status',
                  width: 180,
                  editable: false,
                  renderCell: (params) => {
                    let color = (params.value === 'canceled' && 'error') || 'secondary';
                    if (params.value === "completed") {
                      color = 'success';
                    } else if (params.value === "processing") {
                      color = 'primary';
                    }
                    else if (params.value === "submitted") {
                      color = 'warning';
                    }
                    return <Label
                      variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                      color={color} >
                      {sentenceCase(params.value)}
                    </Label>
                  },
                  valueGetter: (params) => params.row.status
                },
                {
                  field: 'transaction_type',
                  headerName: 'Transaction type',
                  width: 180,
                  editable: false,
                  valueGetter: (params) => `${params.row.transaction_type}`
                },
                {
                  field: 'payment_received',
                  headerName: 'Payment',
                  width: 180,
                  editable: false,
                  renderCell: (params) => {
                    const color = params.value === false ? 'warning' : 'success';
                    const label = params.value === false ? 'Pending' : 'Received';
                    return <Label
                      variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                      color={color} >
                      {label}
                    </Label>
                  },
                  valueGetter: (params) => params.value
                },
                {
                  field: 'staff_name',
                  headerName: 'Assigned to',
                  width: 180,
                  editable: false,
                },
                {
                  field: 'on_hold',
                  headerName: 'On hold',
                  width: 180,
                  editable: false,
                  renderCell: (params) => {
                    const color = (params.value === true && 'error') || 'secondary';
                    return <Label
                      variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                      color={color} >
                      {params.value === true ? "Yes" : "No"}
                    </Label>
                  },
                  valueGetter: (params) => params.row.status
                },
                {
                  field: 'exchange_rate',
                  headerName: 'Exchange rate',
                  width: 180,
                  editable: false,
                },
                {
                  field: 'total_quantity',
                  headerName: 'Total Quantity',
                  width: 220,
                  editable: false,
                  valueGetter: (params) => `${params.row.total_quantity}(${String(params.row.coin_id).toLocaleUpperCase()})`
                },
                {
                  field: 'coin_usd_price',
                  headerName: 'Coin market price',
                  width: 180,
                  editable: false,
                  valueGetter: (params) => `$${fNumber(params.row.coin_usd_price)}`
                },
                {
                  field: 'settlement_amount',
                  headerName: 'Settlement amount',
                  width: 180,
                  editable: false,
                  valueGetter: (params) => `NGN${fNumber(params.value)}`
                },
                {
                  field: 'final_amount',
                  headerName: 'Final amount',
                  width: 180,
                  editable: false,
                  valueGetter: (params) => `$${fNumber(params.value)}`
                },
                {
                  field: 'final_settlement_amount',
                  headerName: 'Final settlement amount',
                  width: 180,
                  editable: false,
                  renderCell: (params) => `${params.row.currency}${fNumber(params.value)}`,
                  valueGetter: (params) => `${fNumber(params.value)}`,
                },
                {
                  field: 'processing_charges',
                  headerName: 'Processing charges($)',
                  width: 180,
                  editable: false,
                  renderCell: (params) => `$${fNumber(params.value)}`,
                  valueGetter: (params) => `${fNumber(params.value)}`,
                },
                {
                  field: 'settlement_charges',
                  headerName: 'Settlement transaction charges',
                  width: 180,
                  editable: false,
                  renderCell: (params) => `${params.row.currency}${fNumber(params.value)}`,
                  valueGetter: (params) => `${fNumber(params.value)}`,
                },
                {
                  field: 'final_rate',
                  headerName: 'Market sell rate',
                  width: 180,
                  editable: false,
                  renderCell: (params) => `$${fNumber(params.value)}`,
                  valueGetter: (params) => `${fNumber(params.value)}`,
                },
                user.is_admin ?
                  {
                    field: 'gross_amount',
                    headerName: 'Gross amount',
                    width: 180,
                    editable: false,
                    renderCell: (params) => `${params.row.currency}${fNumber(params.value)}`,
                    valueGetter: (params) => `${fNumber(params.value)}`,
                  } : {},
                user.is_admin ?
                  {
                    field: 'gross_profit',
                    headerName: 'Gross profit',
                    width: 180,
                    editable: false,
                    renderCell: (params) => `${params.row.currency}${fNumber(params.value)}`,
                    valueGetter: (params) => `${fNumber(params.value)}`,
                  } : {},
                user.is_admin ?
                  {
                    field: 'net_profit',
                    headerName: 'Net profit',
                    width: 180,
                    editable: false,
                    renderCell: (params) => `${params.row.currency}${fNumber(params.value)}`,
                    valueGetter: (params) => `${fNumber(params.value)}`,
                  } : {},
                {
                  field: 'created_at',
                  headerName: 'Created at',
                  width: 180,
                  editable: false,
                  valueGetter: (params) => fDateTime(params.value),
                },
                {
                  field: 'updated_at',
                  headerName: 'Updated at',
                  width: 180,
                  editable: false,
                  valueGetter: (params) => fDateTime(params.value),
                },

              ]}

              // rowsPerPageOptions={[50,100,250,500]}
              loading={state.loading}
              checkboxSelection
              disableRowSelectionOnClick
              components={{ Toolbar: CustomGridToolbar }}
              disableSelectionOnClick

              // handle pagination
              paginationMode="server"
              rowCount={data.total_size}
              page={data.current_page - 1}
              rowsPerPage={data.data.length}
              onPageChange={(newPage) => {
                setQuery({ ...query, page: newPage + 1 });
                getInitData(newPage + 1);
              }}
              pageSize={data.page_size}
              onPageSizeChange={(newPage) => setQuery({ ...query, page_size: newPage })}
              rowsPerPageOptions={[]}

            />
          </Box>

        </Card>
      </Container>

      {profileDialog.open && <ProfileDialog
        user={profileDialog.user}
        handleClose={() => {
          setProfileDialog({ open: false });
        }}
        refresh
      />}


      {orderDialog.open && <OrderDialog
        item={orderDialog.order}
        handleClose={(refresh) => {
          setOrderDialog({ open: false });
          if (refresh) {
            getInitData();
          }
        }}
      />}


      {dailog.open && <SearchQueryDailog
        open={dailog.open}
        hint='Search database for matching records'
        label="Enter order ID"
        handleClose={() => {
          setDailog({ open: false });
        }}
        onSubmit={(val) => {
          setDailog({ open: false });
          findOder(val);
          document.body.focus();
        }}
      />}

      {dailog2.open && <OrderCreateModal
        open={dailog2.open}
        handleClose={() => {
          setDailog2({ open: false });
        }}
        onContinue={(order) => {
          setDailog2({ open: false });
          setOrderDialog({ open: true, order });
        }}
      />}


    </Page>
  );

  async function getInitData(page = null) {

    setState({ ...state, loading: true, loadComplete: false, error: null });

    const sdate = sort.start_date ? new Date(sort.start_date).toDateString() : '';
    const edate = sort.end_date ? new Date(sort.end_date).toDateString() : '';

    try {
      let str = `?sort_field=${sort.field}&sort_order=${sort.order}&status=${sort.status}&user_id=${sort.user_id}`;
      str += `&page=${page || query.page}&page_size=${query.page_size}&transaction_type=${sort.transaction_type}`;
      str += `&start_date=${sdate}&end_date=${edate}`;

      const response = await axios.get(CRUD.order.list + str);

      if (isMountedRef.current && response.data) {
        setData(response.data);
        setState({ ...state, loading: false, loadComplete: true, error: null })
      }
    } catch (error) {
      enqueueSnackbar(getErrorMessage(error));
      setState({ ...state, loading: false, loadComplete: false, error: getErrorMessage(error) })
    }
  };


  async function findOder(query) {

    setState({ ...state, loading: true });
    setSearch({ open: true, query, list: [] });

    try {
      const str = `?query=${query}`;
      const response = await axios.get(CRUD.order.search + str);

      if (isMountedRef.current) {
        setSearch({ open: true, query, list: response.data });
        setState({ ...state, loading: false });
      }
    } catch (error) {
      setState({ ...state, loading: false });
      enqueueSnackbar(getErrorMessage(error));
    }
  };

  async function getStaffs() {
    setStafState({ ...stafState, loading: true, loadComplete: false, error: null })

    try {
      const response = await axios.get(CRUD.admin.list);

      if (isMountedRef.current) {
        setStafState({ ...stafState, loading: false, loadComplete: true, error: null, list: response.data });
      }
    } catch (error) {
      // console.error(error);
      setStafState({ ...stafState, loading: false, loadComplete: false, error: getErrorMessage(error) })
    }
  }
}
