/* eslint-disable import/order */
import {useState, forwardRef, useMemo, useEffect} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
// import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { GridCloseIcon } from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import { Box, Tab, Card, Tabs, Container, IconButton, Grid, Stack, Skeleton  } from '@mui/material';
import Iconify from 'components/Iconify';
import { styled } from '@mui/material/styles';
import ProfileCover from './ProfileCover';
import { capitalCase } from 'change-case';
import ProfileStatInfo from './ProfileStatInfo';
import ProfileAbout from './ProfileAbout';
import ReferralHistory from './profile/ReferralHistory';
import ProfileSettings from './profile/ProfileSettings';
import axiosInstance, { getErrorMessage } from 'utils/axios';
import CRUD from 'utils/Crud';
import { useSnackbar } from 'notistack';
import useIsMountedRef from 'hooks/useIsMountedRef';
import ProfileSettlementBanks from './ProfileSettlementBanks'; 
import ProfileGeneral from './ProfileGeneral';

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const TabsWrapperStyle = styled('div')(({ theme }) => ({
    zIndex: 9,
    bottom: 0,
    width: '100%',
    display: 'flex',
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'center',
    },
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-end',
      paddingRight: theme.spacing(3),
    },
  }));


ProfileDialog.propTypes = {
    user: PropTypes.object,
    handleClose: PropTypes.func,
    refresh: PropTypes.bool,
}

export default function ProfileDialog({user,handleClose,refresh}) {
    const [currentTab, setCurrentTab] = useState('general');
    const [state,setState] = useState({user, wallet:{},pending_balance:0,settlemnt_banks:[], loading:false,loadComplete:false,error:null,devices:[]});
    // const [state,setState] = useState({});
    const handleChangeTab = (newValue) => {
        setCurrentTab(newValue);
    };
    const { enqueueSnackbar } = useSnackbar();
    const isMountedRef = useIsMountedRef();

    const PROFILE_TABS = useMemo(()=>[
        {
          value: 'general',
          icon: <Iconify icon={'ic:round-perm-media'} width={20} height={20} />,
          component: <ProfileGeneral devices={state.devices} settlementBanks={state.settlement_banks} />,
        },
        {
          value: 'referrals',
          icon: <Iconify icon={'ic:round-perm-media'} width={20} height={20} />,
          component: <ReferralHistory user={state.user} />,
        },
        {
          value: 'settings',
          icon: <Iconify icon={'ic:round-perm-media'} width={20} height={20} />,
          component: <ProfileSettings user={state.user} />,
          },
    ],[user,state.devices,state.settlemnt_banks]);

    useEffect(()=>{
      getInitData();
    },[]);
    
  return (
    <>
      <Dialog
        fullScreen
        open
        onClose={handleClose}
        TransitionComponent={Transition}
        // scroll='body'
      >
        <AppBar color='transparent' sx={{ position: 'relative', }}>
          <Toolbar>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {""}
            </Typography>
            <IconButton color='error' onClick={handleClose}>
                <GridCloseIcon  />
            </IconButton>
          </Toolbar>
        </AppBar>

        {state.loadComplete && <Container sx={{mt:3}}>
            <Card
                sx={{
                    mb: 3,
                    height: 280,
                }}
                >
                <ProfileCover user={state.user} />
                <TabsWrapperStyle>
                    <Tabs
                    value={currentTab}
                    scrollButtons="auto"
                    variant="scrollable"
                    allowScrollButtonsMobile
                    onChange={(e, value) => handleChangeTab(value)}
                    >
                    {PROFILE_TABS.map((tab) => (
                        <Tab disableRipple key={tab.value} value={tab.value} icon={tab.icon} label={capitalCase(tab.value)} />
                    ))}
                    </Tabs>
                </TabsWrapperStyle>
            </Card>
            <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                    <Stack spacing={3}>
                        <ProfileStatInfo wallet={state.wallet} pendingBalance={state.pending_balance} loading={state.loading} />
                        <ProfileAbout 
                          user={state.user}
                          onUpdate={handleClose}
                        />
                    </Stack>
                    <br/> <br/>
                </Grid>
                <Grid item xs={12} md={8}>
                    {PROFILE_TABS.map((tab) => {
                        const isMatched = tab.value === currentTab;
                        return isMatched && <Box key={tab.value}>{tab.component}</Box>;
                    })}
                </Grid>
            </Grid>
        </Container>}
        {!state.loadComplete && (
          <Container sx={{mt:5}}>
            <Grid container spacing={5}>
              <Grid item>
                <Skeleton sx={{width:330}} height={80} />
                <Skeleton  height={80} />
              </Grid>
              <Grid item>
                <Skeleton sx={{width:400}} height={80} />
                <Skeleton height={80} />
                <Skeleton height={80} />
                <Skeleton height={80} />
                <Skeleton sx={{width:120}} height={80} />
                <Skeleton sx={{width:120}} height={80} />
              </Grid>
            </Grid>
          </Container>
        )}
      </Dialog>
    </>
  );


  async function getInitData() {
    
    setState({...state,loading:true,loadComplete:false,error:null});

    try {
      const str = `?user_id=${user.user_id}`;

      const ret = await axiosInstance.get(CRUD.user.details+str);
      if (isMountedRef.current) {
        setState({...state,loading:false,loadComplete:true,error:null, ...ret.data});
      }
      
    } catch (error) {
      // {variant:'error'}
      if (isMountedRef.current) {
        enqueueSnackbar(getErrorMessage(error),{variant:'error'});
        setState({...state,loading:false,loadComplete:false,error: getErrorMessage(error)})
      }
    }
  };
}