import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


SearchQueryDailog.propTypes = {
    label: PropTypes.string,
    hint: PropTypes.string,
    handleClose: PropTypes.func,
    onSubmit: PropTypes.func,
}

export default function SearchQueryDailog({onSubmit,handleClose,label,hint}) {
//   const [open, setOpen] = React.useState(false)

  return (
    <>
      <Dialog open onClose={handleClose}>
        <DialogTitle>Search Query</DialogTitle>
        <form id="search_dailog" >
            <DialogContent>
            <DialogContentText>
                {hint}
            </DialogContentText>
            <TextField
                autoFocus
                margin="dense"
                id="search_query"
                label={label}
                fullWidth
                variant="standard"
                required
            />
            </DialogContent>
            <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type='submit' variant='contained' 
                onClick={(e)=>{
                    onSubmit(document.getElementById('search_query').value);
                    e.preventDefault();
                }}>Submit</Button>
            </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
