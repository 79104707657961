import { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Modal, Box, Typography, FormControlLabel, Card, Grid, Stack, Switch } from '@mui/material';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import axiosInstance, { getErrorMessage } from 'utils/axios';
import { FormProvider, RHFSelect, RHFSwitch, RHFTextField } from 'components/hook-form';
import { LoadingButton } from '@mui/lab';
import Label from 'components/Label';
import CRUD from 'utils/Crud';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '2px solid whitesmoke',
    boxShadow: 24,
    p: 2,
};


const NewUserSchema = Yup.object().shape({
    first_name: Yup.string().required('Name is required'),
    last_name: Yup.string().required('Name is required'),
    phone: Yup.string().required('Phone is required'),
    email: Yup.string().required('Email is required').email(),
    role: Yup.string().required('Role Number is required'),
    password: Yup.string().matches(
        // eslint-disable-next-line no-useless-escape
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
      ),
    conf_password: Yup.string().oneOf([Yup.ref('password')], 'Passwords do not match'),
});

AddAdminModal.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func,
    handleComplete: PropTypes.func,
    user: PropTypes.object,
    isEdit: PropTypes.bool,
}

export default function AddAdminModal({ open, isEdit, handleClose, user, handleComplete }) {

    const { enqueueSnackbar } = useSnackbar();

    const defaultValues = useMemo(
        () => ({
            first_name: user?.first_name || '',
            last_name: user?.last_name || '',
            email: user?.email || '',
            phone: user?.phone || '',
            status: user?.status,
            role: user?.role || '',
            // password: isEdit ? null : '',
            // conf_password: isEdit ? null : '',
            is_admin: user?.is_admin ? "admin" :"staff" || ''
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [user]
    );

    const methods = useForm({
        resolver: yupResolver(NewUserSchema),
        defaultValues,
    });

    const {
        reset,
        watch,
        control,
        setValue,
        handleSubmit,
        formState: { isSubmitting },
    } = methods;

    const values = watch();

    useEffect(() => {
        if (isEdit && user) {
          reset(defaultValues);
        }
        if (!isEdit) {
          reset(defaultValues);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [isEdit, user]);

      const onSubmit = async () => {
        try {
            
            values.staff_id = isEdit ? user.staff_id : null;
            await axiosInstance.post(isEdit ? CRUD.admin.update : CRUD.admin.add,values);
            
            reset();
            enqueueSnackbar(!isEdit ? 'Create success!' : 'Update success!');
            handleComplete();
        } catch (error) {

        //   console.error(error);
          enqueueSnackbar(getErrorMessage(error));
        }
      };

    return <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        hideBackdrop={false}
    >
        <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
                {!isEdit ? 'Create User' : 'Update Details'}
            </Typography>

            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>

                <Card sx={{ p: 2, mt:2 }}>

                    <Box
                    >
                        <Grid container spacing={3}>
                            <Grid item sx={12}>
                                <RHFTextField name="first_name" label="First Name" sx={{mb:3}} />
                            </Grid>
                            <Grid item sx={12}>
                                <RHFTextField name="last_name" label="Last Name" sx={{mb:3}} />
                            </Grid>
                        </Grid>
                        
                        <RHFTextField name="email" label="Email Address" sx={{mb:3}} />
                        <RHFTextField name="phone" label="Phone Number" sx={{mb:3}} />
                        <RHFTextField name="role" label="Role" sx={{mb:3}} />

                        <RHFSelect name="is_admin" label="Admin status" placeholder="Admin status" required >
                            <option value="" />
                            {['admin',"staff"].map((option) => (
                            <option key={option} value={option}>
                                {option}
                            </option>
                            ))}
                        </RHFSelect>

                        {isEdit && (
                            <FormControlLabel
                                labelPlacement="start"
                                control={
                                <Controller
                                    name="status"
                                    control={control}
                                    render={({ field }) => (
                                    <Switch
                                        {...field}
                                        checked={field.value === 'active'}
                                        onChange={(event) => field.onChange(!event.target.checked ? 'banned' : 'active')}
                                    />
                                    )}
                                />
                                }
                                label={
                                <>
                                    <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                                    Account status
                                    </Typography>
                                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                    change to enable/disable account
                                    </Typography>
                                </>
                                }
                                sx={{ mx: 0, mb: 3, mt:3, width: 1, justifyContent: 'space-between' }}
                            />
                            )}
                    </Box>
                    <br/>
                    <p>{isEdit ? "Change password (leave empty to for current password) " : "Set password"}</p>
                    <RHFTextField name="password" label="Password" sx={{mb:3, mt:3}} required={!isEdit} type="password"  />
                    <RHFTextField name="conf_password" label="Confirm password" sx={{mb:2}} required={!isEdit} type="password" />

                    <Stack alignItems="flex-end" sx={{ mt: 1 }}>
                        <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                        {!isEdit ? 'Create User' : 'Save Changes'}
                        </LoadingButton>
                    </Stack>
                </Card>

            </FormProvider>
        </Box>
    </Modal>
}