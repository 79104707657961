/* eslint-disable import/order */
import PropTypes from 'prop-types';
// @mui
import { Box,Stack } from '@mui/material';
// components
import { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import axios, { getErrorMessage } from 'utils/axios';
import CRUD from 'utils/Crud';
import { useSnackbar } from 'notistack';
import { FormProvider, RHFTextField } from 'components/hook-form';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
// ----------------------------------------------------------------------

const NewUserSchema = Yup.object().shape({
  passcode: Yup.number().moreThan(59999), // ensure composry 6 digit number
  confirm_passcode: Yup.number().oneOf([Yup.ref('passcode')], 'passcode do not match'),
});

ChangePasscode.propTypes = {
  user: PropTypes.object.isRequired,
};

export default function ChangePasscode({ user }) {

  // const isMountedRef = useIsMountedRef();
  const [state,setState] = useState({loading:false,loadComplete:false,error:null});
  const { enqueueSnackbar } = useSnackbar();

  const methods = useForm({ resolver: yupResolver(NewUserSchema),defaultValues:{
    passcode:'',
    password: '',
    confirm_passcode:'',
    confirm_password: ''
  }});

    const {
        reset,
        watch,
        setValue,
        handleSubmit,
        formState: { isSubmitting },
    } = methods;

    const values = watch();
  // const updateData = (k, v) => setData((prev) => ({ ...prev, [k]: v }));

  return (
    <Box sx={{ mt: 1 }}>
       <Box sx={{ width:"100%", padding:2, mt:4}}>
            
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
              <br/><p>Change App passcode</p> <br/>
              <RHFTextField name="passcode" label="New Passcode" sx={{ mb: 3 }} />
              <RHFTextField name="confirm_passcode" label="Confirm passcode" sx={{ mb: 3 }} />

              <Stack alignItems="flex-end" sx={{ mt: 1 }}>
                  <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                  Update settings
                  </LoadingButton>
              </Stack>
            </FormProvider>
          </Box>

    </Box>
  );

  
  async function onSubmit() {
    setState({...state,loading:true,loadComplete:false,error:null});

    if(values.password === '' && values.passcode === '') return;

    try {
      values.user_id = user.user_id;
      const response = await axios.post(CRUD.user.security_changes, values);
      setState({...state,loading:false,loadComplete:true,error:null});
      reset();
      enqueueSnackbar("Updated sucessfully");
    } catch (error) {
      enqueueSnackbar(getErrorMessage(error));
      setState({...state,loading:false,loadComplete:false});
    }
  };
}
