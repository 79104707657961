/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Modal, Box, Typography, Card, Stack, } from '@mui/material';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css'
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import axiosInstance, { getErrorMessage } from 'utils/axios';
import { FormProvider, RHFTextField, RHFUploadAvatar } from 'components/hook-form';
import { LoadingButton } from '@mui/lab';
import CRUD from 'utils/Crud';
import { fData } from 'utils/formatNumber';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '2px solid whitesmoke',
    boxShadow: 24,
    p: 4,
};


const NewUserSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'), 
    intro: Yup.string().required('Intro is required'), 
    role: Yup.string().required('Role is required'),
});

AddAgentModal.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func,
    handleComplete: PropTypes.func,
}

export default function AddAgentModal({ open, handleClose,handleComplete }) {

    const { enqueueSnackbar } = useSnackbar();
    const [state,setState] = useState({imageRef:null, croppedImageUrl:null, croppedImage:null});
    const [crop, setCrop] = useState({
        unit: '%', // Can be 'px' or '%'
        x: 25,
        y: 25,
        width: 85,
        height: 85,
        aspect: .85 / 1,
      });

    const methods = useForm({
        resolver: yupResolver(NewUserSchema),
        defaultValues:{name:"", role:"Customer support"},
    });

    const {
        reset,
        watch,
        control,
        setValue,
        handleSubmit,
        formState: { isSubmitting },
    } = methods;

    const values = watch();

    const onSubmit = async () => {
        try {
            
            const formData = new FormData()
            formData.append('name', values.name);
            formData.append('role', values.role); 
            formData.append('intro', values.intro);
            formData.append('userfile', state.croppedImage)
            await axiosInstance.post(CRUD.supportAgent,formData);
            reset();
            enqueueSnackbar('Create success!');
            handleComplete();
        } catch (error) {

        //   console.error(error);
            enqueueSnackbar(getErrorMessage(error));
        }
    };

    const handleDrop = useCallback(
        (acceptedFiles) => {
        const file = acceptedFiles[0];

        if (file) {
        setValue(
            'avatarUrl',
            Object.assign(file, {
                preview: URL.createObjectURL(file),
            })
        );
        }
    },
    [setValue]
      );

    return <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        hideBackdrop={false}
    >
        <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
            Create User
            </Typography>

            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>

                <Card sx={{ p: 3, mt:4 }}>

                    <Box  >
                        <Box sx={{ mb: 5 }}>
                            {values.avatarUrl == null &&
                                <RHFUploadAvatar
                                    name="avatarUrl"
                                    accept="image/png,image/jpg,image/jpeg"
                                    maxSize={3145728}
                                    onDrop={handleDrop}
                                    helperText={
                                    <Typography
                                        variant="caption"
                                        sx={{
                                        mt: 2,
                                        mx: 'auto',
                                        display: 'block',
                                        textAlign: 'center',
                                        color: 'text.secondary',
                                        }}
                                    >
                                        Allowed *.jpeg, *.jpg, *.png, *.gif
                                        <br /> max size of {fData(3145728)}
                                    </Typography>
                                    }
                                />}
                            {values.avatarUrl != null && (
                                <Box sx={{display:"flex",justifyContent:"center"}}>
                                    <ReactCrop 
                                        crop={crop} 
                                        circularCrop
                                        onComplete={onCropComplete}  
                                        onChange={c => setCrop(c)}>
                                        <img 
                                            id="image_holder"
                                            onLoad ={(image)=>{
                                                // console.log(image)
                                                // setState({...state,imageRef:image.currentTarget});
                                                // onCropComplete(image.currentTarget,crop);
                                                getCroppedImg(crop);
                                            }} 
                                            src={values.avatarUrl.preview} 
                                            style={{width:180}} alt="" />
                                    </ReactCrop>
                                </Box>
                                
                            ) }
                            </Box>
                        
                        <RHFTextField name="name" label="Name" sx={{mt:3}} />  
                        <RHFTextField name="role" label="Role" sx={{mt:3}} />  
                        <RHFTextField name="intro" label="Chat Message" sx={{mt:3}} />  
                        <input type='file' accept='image/*' id="userfile" style={{display:"none"}} />                 
                        
                    </Box> 
                    <br/>
                    <Stack alignItems="flex-end" sx={{ mt: 3 }}>
                        <LoadingButton type="submit" size="large" variant="contained" loading={isSubmitting}>
                        Create Agent
                        </LoadingButton>
                    </Stack>
                </Card>

            </FormProvider>
        </Box>
    </Modal>

    function dataURLtoFile(dataurl, filename) {
        const arr = dataurl.split(',');
            const mime = arr[0].match(/:(.*?);/)[1];
            const bstr = atob(arr[1]); 
            let n = bstr.length; 
            const u8arr = new Uint8Array(n);
                
        // eslint-disable-next-line no-plusplus
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        const croppedImage = new File([u8arr], filename, {type:mime});
        setState({...state,croppedImage });
    }

    function getCroppedImg(crop) {
        const image =  document.getElementById("image_holder");
        const canvas = document.createElement("canvas");
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext("2d");
        
        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
         );
    
        const reader = new FileReader()
        canvas.toBlob(blob => {
            reader.readAsDataURL(blob)
            reader.onloadend = () => {
                dataURLtoFile(reader.result, 'userfile.jpg')
            }
        });
    }

    function onCropComplete(crop) {
        getCroppedImg(crop);
    }
}
