import PropTypes from 'prop-types';
// @mui
import { Box, Card, Typography, Divider } from '@mui/material';
// components
import ChangePassword from './ChangePassword';
import ChangePasscode from './ChangePasscode';
// ----------------------------------------------------------------------

ProfileSettings.propTypes = {
  user: PropTypes.object,
};

export default function ProfileSettings({ user }) {
  return (
    <Box sx={{ mt: 1 }}>
      <Card sx={{padding:3}}>
        <Typography variant="h6" sx={{ mb: 3 }}>
          Security options
        </Typography>

          <ChangePassword user={user} />
          <br/>
          <Divider />
          <ChangePasscode user={user} />
      </Card>

    </Box>
  );
}
