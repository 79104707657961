// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  booking: getIcon('ic_booking'),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [
      { title: 'Dashboard', path: PATH_DASHBOARD.general.overview, icon: ICONS.dashboard },
      { title: 'Users overview', path: PATH_DASHBOARD.general.usersOverview, icon: ICONS.ecommerce },
      { title: 'Transaction overview', path: PATH_DASHBOARD.general.transactionOverview, icon: ICONS.analytics },
    ],
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'management',
    items: [
      // MANAGEMENT : USER
      {
        title: 'users',
        path: PATH_DASHBOARD.user.root,
        icon: ICONS.user,
        children: [
          { title: 'All users', path: PATH_DASHBOARD.user.list },
          { title: 'Wallet balance', path: PATH_DASHBOARD.user.wallet },
          // { title: 'Banned account', path: PATH_DASHBOARD.user.wallet },
        ],
      },

      // MANAGEMENT : E-COMMERCE
      {
        title: 'Orders',
        path: PATH_DASHBOARD.order.root,
        icon: ICONS.cart,
        children: [
          { title: 'Crptocurrencies', path: PATH_DASHBOARD.order.list },
          { title: 'Giftcards', path: PATH_DASHBOARD.order.giftcard },
          // { title: 'Giftcards', path: PATH_DASHBOARD.eCommerce.shop },
        ],
      },

      
      // { title: 'Giftcard order', path: PATH_DASHBOARD.blog.posts, icon: ICONS.blog },

      {
        title: 'Rewards & Bonus',
        path: PATH_DASHBOARD.rewards.root,
        icon: ICONS.blog,
        children: [
          { title: 'referral ranking', path: PATH_DASHBOARD.rewards.referralRanking },
          { title: 'Monthly pending bonus', path: PATH_DASHBOARD.rewards.monthlyBonus },
        ],
      },

      {
        title: 'Market & trading',
        path: PATH_DASHBOARD.trading.root,
        icon: ICONS.blog,
        children: [
          
          {
            title: 'Assets list',
            path: PATH_DASHBOARD.trading.list,
            icon: ICONS.kanban,
          },
          {
            title: 'Exchange rates',
            path: PATH_DASHBOARD.trading.rates,
            icon: ICONS.kanban,
          },
          {
            title: 'Wallet address',
            path: PATH_DASHBOARD.trading.wallet,
            icon: ICONS.kanban,
          },
          {
            title: 'Sales support',
            path: PATH_DASHBOARD.trading.salesSupport,
            icon: ICONS.kanban,
          },
        ], 
      },

      { title: 'Featured posts', path: PATH_DASHBOARD.featuredPosts, icon: ICONS.blog },

      // { title: 'Collection Bank Accounts', path: PATH_DASHBOARD.bankAccount, icon: ICONS.blog },
      {
        title: 'Collections',
        path: PATH_DASHBOARD.collections.root,
        icon: ICONS.blog,
        children: [
          {
            title: 'Bank accounts',
            path: PATH_DASHBOARD.collections.bankAccount,
            icon: ICONS.kanban,
          },
          {
            title: 'Incoming request',
            path: PATH_DASHBOARD.collections.imcoming,
            icon: ICONS.kanban,
          },
          {
            title: 'Providus settlement',
            path: PATH_DASHBOARD.collections.providusSettlement,
            icon: ICONS.kanban,
          },
        ],
      },
      
    ],
  },

  // APP
  // ----------------------------------------------------------------------
  {
    subheader: 'System',
    items: [

      { title: 'Support agent', path: PATH_DASHBOARD.system.supportAgent, icon: ICONS.chat },
      {
        title: 'Admin account',
        path: PATH_DASHBOARD.system.adminAccount,
        icon: ICONS.user,
        
      },

      { title: 'Activity log', path: PATH_DASHBOARD.activityLog, icon: ICONS.blog },


      {
        title: 'Configurations',
        path: PATH_DASHBOARD.order.root,
        icon: ICONS.blog,
        children: [
          {
            title: 'Variables',
            path: PATH_DASHBOARD.system.configurations.variables,
            icon: ICONS.kanban,
          },
          // {
          //   title: 'Giftcard contacts',
          //   path: PATH_DASHBOARD.system.configurations.giftcardVendors,
          //   icon: ICONS.kanban,
          // },
        ],
      },

      {
        title: 'Database log',
        path: PATH_DASHBOARD.database.root,
        icon: ICONS.blog,
        children: [
          { title: 'transactions', path: PATH_DASHBOARD.database.transactions },
          { title: 'bills payment', path: PATH_DASHBOARD.database.billsPayment },
          { title: 'Paystack transfers', path: PATH_DASHBOARD.database.paystack },
          { title: 'Query selector', path: PATH_DASHBOARD.database.selector },
          // { title: 'Providus settlement', path: PATH_DASHBOARD.database.providusSettlement },
          // { title: 'Virtual accounts', path: PATH_DASHBOARD.database.virtualAccounts },
        ],
      },
      
      
    ],
  },
];

export const staffNavConfig = [
  {
    subheader: 'general',
    items: [
      { title: 'Dashboard', path: PATH_DASHBOARD.general.overview, icon: ICONS.dashboard },
    ],
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'management',
    items: [
      // MANAGEMENT : USER
      {
        title: 'users',
        path: PATH_DASHBOARD.user.root,
        icon: ICONS.user,
        children: [
          { title: 'All users', path: PATH_DASHBOARD.user.list },
        ],
      },

      // MANAGEMENT : E-COMMERCE
      {
        title: 'Orders',
        path: PATH_DASHBOARD.order.root,
        icon: ICONS.cart,
        children: [
          { title: 'Crptocurrencies', path: PATH_DASHBOARD.order.list },
          { title: 'Giftcards', path: PATH_DASHBOARD.order.giftcard },
        ],
      },

      {
        title: 'Rewards & Bonus',
        path: PATH_DASHBOARD.rewards.root,
        icon: ICONS.blog,
        children: [
          { title: 'referral ranking', path: PATH_DASHBOARD.rewards.referralRanking },
          { title: 'Monthly pending bonus', path: PATH_DASHBOARD.rewards.monthlyBonus },
        ],
      },

      { title: 'Featured posts', path: PATH_DASHBOARD.featuredPosts, icon: ICONS.blog },

      {
        title: 'Collections',
        path: PATH_DASHBOARD.collections.root,
        icon: ICONS.blog,
        children: [
          {
            title: 'Incoming request',
            path: PATH_DASHBOARD.collections.imcoming,
            icon: ICONS.kanban,
          },
          
        ],
      },
      
    ],
  },
];

export default navConfig;
