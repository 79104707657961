/* eslint-disable import/order */
import PropTypes from 'prop-types';
// @mui
import { styled } from '@mui/material/styles';
import { Link, Card, Typography, CardHeader, Stack, Button, Box, Alert } from '@mui/material';
// components
import { fDateTime } from 'utils/formatTime';
import Iconify from '../../../components/Iconify';
import { useState } from 'react';
import ReviewKYCModal from './profile/ReviewKYCModal';

// ----------------------------------------------------------------------

const IconStyle = styled(Iconify)(({ theme }) => ({
  width: 20,
  height: 20,
  marginTop: 1,
  flexShrink: 0,
  marginRight: theme.spacing(2),
}));

// ----------------------------------------------------------------------

ProfileAbout.propTypes = {
  user: PropTypes.object,
  onUpdate: PropTypes.func.isRequired
};

export default function ProfileAbout({ user,onUpdate }) {

  const [modal,setModal] = useState(false);

  return (
    <>
    <Card>
      <CardHeader title="About" />
      <Stack spacing={2} sx={{ p: 3 }}>
        <Typography variant="body2">Very important user Quickie, handle with care</Typography>

        <Stack direction="row">
          <Typography variant="body2">
            Name: &nbsp;
            <Link component="span" variant="subtitle2" color="text.primary">
              {user.first_name} {user.last_name}
            </Link>
          </Typography>
        </Stack>

        {user.account_verification_status === "pending" && (
        <Stack sx={{mb:2}}>
            <Alert severity="warning">
              <span>Pending verification</span>
              <Button sx={{mt:2}} 
                variant='outlined'
                onClick={()=> setModal(true)}
                >
                  Review account
              </Button>
            </Alert>
        </Stack>
        )}

        <Stack direction="row">
          <IconStyle icon={'eva:pin-fill'} />
          <Typography variant="body2">
            Live at &nbsp;
            <Link component="span" variant="subtitle2" color="text.primary">
              Nigeria
            </Link>
          </Typography>
        </Stack>

        <Stack direction="row">
          <IconStyle icon={'eva:email-fill'} />
          <Typography variant="body2">{user.email}</Typography>
        </Stack>

        <Stack direction="row">
          <IconStyle icon={'ic:round-business-center'} />
          <Typography variant="body2">
            {user.phone}
          </Typography>
        </Stack>
        <Stack direction="row">
          <IconStyle icon={'ic:round-business-center'} />
          <Typography variant="body2">
            Date of Birth: {user.dob}
          </Typography>
        </Stack>
        <Stack direction="row">
          <IconStyle icon={'ic:round-business-center'} />
          <Typography variant="body2">
            Gender: {user.gender}
          </Typography>
        </Stack>
        <Stack direction="row">
          <IconStyle icon={'ic:round-business-center'} />
          <Typography variant="body2">
            KYC: {user.phone}
          </Typography>
        </Stack>
        <Stack direction="row">
          <IconStyle icon={'ic:round-business-center'} />
          <Typography variant="body2">
            KYC Document Type: {user.document_type}
          </Typography>
        </Stack>
        <Stack direction="row">
          <IconStyle icon={'ic:round-business-center'} />
          <Typography variant="body2">
            KYC ID Number: {user.document_number}
          </Typography>
        </Stack>
        <Stack direction="row">
          <IconStyle icon={'ic:round-business-center'} />
          <Typography variant="body2">
            KYC IP Address: {user.kyc_ip_address}
          </Typography>
        </Stack>
        {user.document_image_url && (
          <Stack direction="row">
            <IconStyle icon={'ic:round-business-center'} />
            <Typography variant="body2">
              KYC Document: <a href={user.document_image_url} target='_blank' rel="noreferrer">View image </a>
            </Typography>
          </Stack>
        )}
        
        <Stack direction="row">
          <IconStyle icon={'ic:round-business-center'} />
          <Typography variant="body2">
            Verification Status: {user.account_verification_status}
          </Typography>
        </Stack>

        <Stack direction="row">
          <IconStyle icon={'ic:round-business-center'} />
          <Typography variant="body2">
            Created at &nbsp;
            <Link component="span" variant="subtitle2" color="text.primary">
              {fDateTime(user.created_at)}
            </Link>
          </Typography>
        </Stack>
        <Stack direction="row">
          <IconStyle icon={'ic:round-business-center'} />
          <Typography variant="body2">
            Last seen at &nbsp;
            <Link component="span" variant="subtitle2" color="text.primary">
              {fDateTime(user.last_seen_at)}
            </Link>
          </Typography>
        </Stack>
      </Stack>
    </Card>

    {modal && (
        <ReviewKYCModal 
          user={user}
          open={modal}
          handleClose={()=>{
            setModal(false);
          }}
          handleComplete={()=>{
            setModal(false);
            onUpdate();
          }}
          />
      )}
      </>
  );
}
