/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable import/order */
import {useState, forwardRef, useMemo, useEffect} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
// import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { GridCloseIcon } from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import { Box, Tab, Card, Tabs, Container, IconButton, Grid, Stack, ImageList, ImageListItem, ImageListItemBar, ListSubheader, Skeleton  } from '@mui/material';
import Iconify from 'components/Iconify';
import useIsMountedRef from 'hooks/useIsMountedRef';
import { styled } from '@mui/material/styles';
import AppColors from "utils/AppColors";
import Swal from 'sweetalert2'
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import OderDetails from './OderDetails';
import Scrollbar from 'components/Scrollbar';
import OrderEventTimeline from './OrderEventTimeline';
import axiosInstance, { getErrorMessage } from 'utils/axios';
import { useSnackbar } from 'notistack';
import CRUD from 'utils/Crud';
import { capitalCase } from 'change-case';
import uuidv4 from 'utils/uuidv4';
import OrderActionModal from './OrderActionModal';

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

OrderDialog.propTypes = {
    item: PropTypes.object,
    handleClose: PropTypes.func,
}

export default function OrderDialog({item,handleClose}) {
  
    const [height, setHeight] = useState(190);
    const [order, setOrder] = useState({...item});
    const [modal, setModal] = useState({open:false,action:'',title:null});
    const [data, setData] = useState({images:[],events:[], bank:{}});
    const [state,setState] = useState({loading:false,loadComplete:false,error:null});
    const { enqueueSnackbar } = useSnackbar();
    const isMountedRef = useIsMountedRef();
    const [currentTab, setCurrentTab] = useState('about');
    const handleChangeTab = (newValue) => {
        setCurrentTab(newValue);
    };


    useEffect(()=>{
      setTimeout(()=>{
        setHeight(document.getElementById("app_bar_height").clientHeight);
      },750);
      getInitData();
    },[]);

    const TABS = useMemo(()=>[
      {
          value: 'about',
          icon: <Iconify icon={'ic:round-perm-media'} width={20} height={20} />,
          component: state.loadComplete ? <OderDetails order={order} bank={data.bank} /> : <Skeleton height={10} />,
      },
      {
          value: 'proof of payment',
          icon: <Iconify icon={'ic:round-perm-media'} width={20} height={20} />,
          component: state.loadComplete ? <PaymentProofTab images={data.images} />  : <Skeleton height={10} />,
          },
  ],[state.loadComplete]);

  return (
    <>
      <Dialog
        fullScreen
        open
        onClose={handleClose}
        TransitionComponent={Transition}
        // scroll='body'
      >
        <AppBar id="app_bar_height" color='transparent' sx={{ position: 'relative', }}>
          <Toolbar>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">{""}</Typography>
            <IconButton color='error' onClick={handleClose}>
                <GridCloseIcon  />
            </IconButton>
          </Toolbar>
        </AppBar>
        {!state.loadComplete && (
          <Container sx={{mt:5}}>
            <Grid container spacing={5}>
              <Grid item>
                <Skeleton sx={{width:330}} height={80} />
                <Skeleton  height={80} />
              </Grid>
              <Grid item>
                <Skeleton sx={{width:400}} height={80} />
                <Skeleton height={80} />
                <Skeleton height={80} />
                <Skeleton height={80} />
                <Skeleton sx={{width:120}} height={80} />
                <Skeleton sx={{width:120}} height={80} />
              </Grid>
            </Grid>
          </Container>
        )}
        {state.loadComplete && (
        <Container sx={{mt:3, maxHeight: document.body.clientHeight - height - 50 }}>
          <Scrollbar>
            <Grid container spacing={3}>
                <Grid item xs={12} md={5}>

                    <OrderEventTimeline events={data.events} loading={state.loading} />

                    <Card sx={{ mb: 3,padding: 3}}>
                      <Typography sx={{  }} variant="h5" component="div">
                        Actions
                      </Typography>
                      <Typography variant="subtitle2" component="p" color="textSecondary">
                        Note: please ensure you have send or received funds/coin before using any of the actions below
                      </Typography>
                      {/* <p>w.</p> */}
                      <Stack mt={5} spacing={3}>
                        <Button 
                          disabled={order.payment_received}
                          variant='outlined' color='secondary'
                          onClick={()=>{
                            setModal({open:true,action:"payment_received",title:"Confirm payment"});
                          }}>
                          I have received {order.transaction_type === "sell" ? "coin" : "funds"}
                        </Button>
                        <Button 
                          variant={ 'outlined' } 
                          type='button'
                          disabled={order.status === "completed" || !order.payment_received}
                          onClick={()=> {
                              setModal({open:true,action:"complete",title:"Complete transaction"});
                            }}  >
                            Complete order
                        </Button>
                        <Button 
                          variant={order.on_hold === true ? 'contained' : 'outlined' } 
                          color="error" type='button'
                          disabled={order.on_hold || order.status === "completed"}
                          onClick={()=> {
                            setModal({open:true,action:"hold",title:"Put on hold"});
                          }}  >
                          Place on hold
                        </Button>
                      </Stack>
                    </Card>
                    
                </Grid>
                <Grid item xs={12} md={7}>

                    <Tabs
                        value={currentTab}
                        scrollButtons="auto"
                        variant="scrollable"
                        allowScrollButtonsMobile
                        onChange={(_e, value) => handleChangeTab(value)}
                        >
                        {TABS.map((tab) => (
                            <Tab disableRipple key={tab.value} value={tab.value} icon={tab.icon} label={capitalCase(tab.value)} />
                        ))}
                    </Tabs>

                    {TABS.map((tab) => {
                        const isMatched = tab.value === currentTab;
                        return isMatched && <Box key={tab.value} sx={{mt:2}}>{tab.component}</Box>;
                    })}
                </Grid>
            </Grid>
          </Scrollbar>
        </Container>)}
      </Dialog>

      {modal.open && (
        <OrderActionModal 
          handleClose={()=> setModal({open:false})}
          onSubmit={(e)=> alert(e)}
          action={modal.action}
          order={order}
          title={modal.title}
          handleComplete={(order)=> {
            setOrder({...order});
            setModal({open:false});
            handleClose(true);
          }}
        />
      )}
    </>
  );

  async function getInitData() {

    setState({...state,loading:true,loadComplete:false,error:null});

    try {
      const ret = await axiosInstance.get(`${CRUD.order.details}?order_id=${order.order_id}`);
      if (isMountedRef.current) {
        setOrder(ret.data.order);
        setState({...state,loading:false,loadComplete:true,error:null});
        setData(ret.data);
      }
    } catch (error) {
      enqueueSnackbar(getErrorMessage(error));
      setState({...state,loading:false,loadComplete:false,error: getErrorMessage(error)})
    }
  };
}


const PaymentProofTab = ({images})=><Card sx={{padding:4}}>
    <ImageList >
      <ImageListItem cols={2}>
        <ListSubheader component="div">Uploaded by User</ListSubheader>
      </ImageListItem>
      {images.map((item) => item.created_by === "user"
          ? <ImageListItem key={item.image_url}>
          <img onClick={()=> window.open(item.image_url,"_blank")}
              srcSet={item.image_url}
              src={item.image_url} sx={{maxWidth:'100%',height:'auto'}} style={{cursor:'pointer'}}
              loading="lazy"
            />
        </ImageListItem>
        : <div key={uuidv4()}>{}</div>)}
      <ImageListItem cols={2}>
        <ListSubheader key={`${uuidv4()}ss`} component="div">Uploaded by staff</ListSubheader>
      </ImageListItem>
      {images.map((item,i) => item.created_by !== "user"
          ? 
          <ImageListItem key={item.image_url}>
            <img onClick={()=> window.open(item.image_url,"_blank")}
              srcSet={item.image_url}
              src={item.image_url} sx={{maxWidth:'100%',height:'auto'}} style={{cursor:'pointer'}}
              loading="lazy"
            />
            <ImageListItemBar
              title={item.tag}
              actionIcon={
                <IconButton
                  sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                  aria-label={`info about ${item.title}`}
                >
                  <Iconify icon={'ic:baseline-info'} size="small" sx={{width:25,height:25, mr:3,position:'relative',top:2}} />
                </IconButton>
              }
            />
        </ImageListItem>
        : <div key={uuidv4()}>{}</div>)}
    </ImageList>
  </Card>

PaymentProofTab.propTypes = {
  images: PropTypes.array,
}