/* eslint-disable camelcase */
import { sentenceCase } from 'change-case';
import { useState, useCallback, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import {
  Card,
  Table,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Box, TableHead,
  CircularProgress,
} from '@mui/material';
// routes
import axios, { getErrorMessage } from 'utils/axios';
import useIsMountedRef from 'hooks/useIsMountedRef';
import CRUD from 'utils/Crud';
import { fDateTime } from 'utils/formatTime';
import AddAdminModal from 'sections/@dashboard/admin/AddAdminModal';
import AppColors from "utils/AppColors";
import Swal from 'sweetalert2'
import { handleSelectClick } from 'utils/commonFunc';
import { PATH_DASHBOARD } from '../../routes/paths';
// hooks
import useSettings from '../../hooks/useSettings';
// components
import Page from '../../components/Page';
import Label from '../../components/Label';
import Iconify from '../../components/Iconify';
import Scrollbar from '../../components/Scrollbar';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';

// ----------------------------------------------------------------------



const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'role', label: 'Role', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'seen_at', label: 'Last seen', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

export default function AdminUserList() {
  const theme = useTheme();
  const { themeStretch } = useSettings();
  const isMountedRef = useIsMountedRef();

  const [userList, setUserList] = useState([]);
  const [modal, setModal] = useState({open:false, user:{}});
  const [state,setState] = useState({loading:false,loadComplete:false,error:null});
  const [selected, setSelected] = useState([]);


  const getAllAdmins = useCallback(async () => {

    setState({...state,loading:true,loadComplete:false,error:null})

    try {
      const response = await axios.get(CRUD.admin.list);

      console.log(response.data)

      if (isMountedRef.current) {
        setUserList(response.data);
      }
      setState({...state,loading:false,loadComplete:true,error:null})
    } catch (error) {
      console.error(error);
      setState({...state,loading:false,loadComplete:false,error: getErrorMessage(error)})
    }
  }, [isMountedRef]);


  useEffect(() => {
    getAllAdmins();
  }, [getAllAdmins]);

  return (
    <Page title="Manage Admins:">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="Admin List"
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            { name: 'Admin', href: PATH_DASHBOARD.system.adminAccount },
            { name: 'List' },
          ]}
          action={
            <Button
              variant="contained"
              component={Button}
              onClick={()=>{
                setModal({open:true, isEdit: false});
              }}
              startIcon={<Iconify icon={'eva:plus-fill'} />}
            >
              New User
            </Button>
          }
        />

        {state.loading && (
          <Card>
             <Box padding={10} display={"flex"} justifyContent={"center"}>
              <CircularProgress />
            </Box>
          </Card>
        )}

        {!state.loading && state.error !=null && (
          <Card>
            <Box padding={10} display={"flex"} justifyContent={"center"}>
            <p>Error {state.error} </p>
            </Box>
          </Card>
        )}

        {state.loadComplete && <Card>
          

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>{" "}</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Role</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Last seen</TableCell>
                    <TableCell>{" "}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {userList.map((row) => {
                    const { id, first_name, last_name, role, email, status, image_url, seen_at } = row;
                    const isItemSelected = selected.indexOf(first_name) !== -1;

                    return (
                      <TableRow
                        hover
                        key={id}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox checked={isItemSelected} onClick={() => handleSelectClick(id, selected, setSelected)} />
                        </TableCell>
                        <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
                          <Avatar alt={first_name} src={image_url} sx={{ mr: 2 }} />
                          <Typography variant="subtitle2" noWrap>
                            {first_name} {last_name} {" "}
                            {row.is_admin && 
                            <Label
                                variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                                color={'secondary'}
                              >
                                Admin
                              </Label>}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">{email}</TableCell>
                        <TableCell align="left">{role}</TableCell>
                        <TableCell align="left">
                          <Label
                            variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                            color={(status === 'inactive' && 'error') || 'success'}
                          >
                            {sentenceCase(status)}
                          </Label>
                        </TableCell>
                        {/* <TableCell align="left">{role}</TableCell> */}
                        <TableCell align="left">{fDateTime(seen_at)}</TableCell>

                        <TableCell align="right">
                          <Button
                            onClick={() =>{
                              setModal({open:true,user:row, isEdit:true});
                            }}
                            variant="outlined" color="secondary"
                            >
                             Edit
                          </Button>
                          <Button sx={{ml:1}}
                            type="submit" 
                            onClick={()=> removeUsers(row)}
                            variant="outlined" color="error"
                            >
                            <Iconify icon={'eva:trash-2-outline'} sx={{  mr: 2, width: 20,height: 20,}} /> Delete
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {/* {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )} */}
                </TableBody>
                
                {state.loadComplete && userList.length < 1 && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Box sx={{padding: 7}}>
                          <Typography>
                            Nothing here yet.
                          </Typography>
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          
        </Card>}
      </Container>

      {modal.open && <AddAdminModal
        open={modal.open}
        user={modal.user}
        isEdit={modal.isEdit}
        handleComplete={()=>{
          setModal({open:false});
          getAllAdmins();
        }}
        handleClose={()=>{
          setModal({open:false});
        }}
         />}
    </Page>
  );


  function removeUsers(row){
    Swal.fire({
        text: "Do you want to remove this Admin?",
        icon: 'warning',
        showCancelButton: true,
        // eslint-disable-next-line import/no-named-as-default-member
        confirmButtonColor: AppColors.DANGER,
        confirmButtonText: "Yes, contine",
        reverseButtons: true,
        showLoaderOnConfirm: true,
        allowOutsideClick: () => !Swal.isLoading(),
        preConfirm: async ()  =>  {
          try {
            await axios.delete(`${CRUD.admin.add}/${row.staff_id}`);
            const data = userList.filter(e=>e.id !== row.id);
            setUserList(data);
            return true;
          } catch (error) {
            Swal.showValidationMessage(
                `${getErrorMessage(error) || error.toString()}`
            );
            return false;
          }
        },
    }).then((result) => {
        if (result.value) {
            Swal.close();
        } 
    });
  }
}