import React from 'react';
import PropTypes from 'prop-types';
import { Card, Skeleton, Typography } from '@mui/material';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from '@mui/lab/TimelineOppositeContent';
import { fDateTime } from 'utils/formatTime';

const OrderEventTimeline = ({events,loading}) => (
        <Card sx={{padding:3, mb:3}}>
            <Typography variant="h6" component="p" sx={{mb:3}}>
                Order event timeline
            </Typography>
            {loading && (
                <>
                <Skeleton height={60} />
                <Skeleton height={60} />
                <Skeleton height={60} />
                </>
            )}
            <Timeline
                sx={{
                    [`& .${timelineOppositeContentClasses.root}`]: {
                    flex: 0.4,
                    }, padding: 0
                }}
                >
                {events.map((el,i)=> <TimelineItem key={i}>
                        <TimelineOppositeContent color="textSecondary">
                           <span style={{fontSize:10}}>{fDateTime(el.created_at)}</span>
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                            <TimelineDot color='success' />
                            {(events.length - 1 !== i || i === 0) && <TimelineConnector /> }
                        </TimelineSeparator>
                        <TimelineContent>
                            <Typography variant="subtitle2" component="div" > {el.title} </Typography>
                            <Typography variant="subheading" component="span" sx={{fontSize:12}} color="textSecondary"> 
                                {el.message} 
                                {el.created_by !== "user" ? ` [${el.staff_name}]` : ''} 
                            </Typography>
                        </TimelineContent>
                    </TimelineItem>)}
            </Timeline>
        </Card>
    );

OrderEventTimeline.propTypes = {
    events: PropTypes.array,
    loading: PropTypes.bool,
}

export default OrderEventTimeline;