/* eslint-disable import/order */
import PropTypes from 'prop-types';
// @mui
import { Box, Stack, } from '@mui/material';
// components
import { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import axios, { getErrorMessage } from 'utils/axios';
import CRUD from 'utils/Crud';
import { useSnackbar } from 'notistack';
import { FormProvider, RHFTextField } from 'components/hook-form';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
// ----------------------------------------------------------------------

const NewUserSchema = Yup.object().shape({
  password: Yup.string().matches(
    // eslint-disable-next-line no-useless-escape
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
    "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
  ),
  confirm_password: Yup.string().oneOf([Yup.ref('password')], 'Passwords do not match')
});

ChangePassword.propTypes = {
  user: PropTypes.object,
};

export default function ChangePassword({ user }) {

  // const isMountedRef = useIsMountedRef();
  const [state,setState] = useState({loading:false,loadComplete:false,error:null});
  const { enqueueSnackbar } = useSnackbar();

  const methods = useForm({ resolver: yupResolver(NewUserSchema),defaultValues:{
    passcode:'',
    password: '',
  }});

    const {
        reset,
        watch,
        setValue,
        handleSubmit,
        formState: { isSubmitting },
    } = methods;

    const values = watch();

  return (
    <Box sx={{ mt: 1 }}>
      <Box sx={{ width:"100%", padding:2, mt:4}}>
            
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <p>Change account password</p> <br/>
          <RHFTextField name="password" label="New Password" sx={{ mb: 3 }} />
          <RHFTextField name="confirm_password" label="Confirm password" sx={{ mb: 3 }} />

          <Stack alignItems="flex-end" sx={{ mt: 1 }}>
              <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
              Update settings
              </LoadingButton>
          </Stack>
        </FormProvider>
      </Box>
    </Box>
  );

  
  async function onSubmit() {
    setState({...state,loading:true,loadComplete:false,error:null});

    if(values.password === '' && values.passcode === '') return;

    try {
      values.user_id = user.user_id;
      const response = await axios.post(CRUD.user.security_changes, values);
      setState({...state,loading:false,loadComplete:true,error:null});
      reset();
      enqueueSnackbar("Updated sucessfully");
    } catch (error) {
      enqueueSnackbar(getErrorMessage(error));
      setState({...state,loading:false,loadComplete:false});
    }
  };
}
