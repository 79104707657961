/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Modal, Box, Typography, Card, Stack, FormControl, TextField, InputLabel, Button, FormLabel, } from '@mui/material';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import axiosInstance, { getErrorMessage } from 'utils/axios';
import { FormProvider, RHFTextField } from 'components/hook-form';
import { LoadingButton } from '@mui/lab';
import CRUD from 'utils/Crud';
import Label from 'components/Label';
import { fNumber } from 'utils/formatNumber';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '2px solid whitesmoke',
    boxShadow: 24,
    p: 2,
};


const NewUserSchema = Yup.object().shape({
    amount: Yup.string(),
    password: Yup.string().required('Password is required'),
    rate: Yup.number()
});

OrderActionModal.propTypes = {
    handleClose: PropTypes.func,
    handleComplete: PropTypes.func,
    action: PropTypes.string,
    title: PropTypes.string,
    order: PropTypes.object,
}

export default function OrderActionModal({ handleClose,handleComplete, action,order, title}) {

    const { enqueueSnackbar } = useSnackbar();
    const [state,setState] = useState({files:[]});

    const formatter = useMemo(()=> Intl.NumberFormat("en-US",{ 
            minimumFractionDigits: 2,
            maximumFractionDigits: 10}),[]);
    const buyRate = useMemo(()=> {
        let r = order.exchange_rate;
        r = r.replace("/$1");
        return parseInt(r, 10);
    },[]);

    const methods = useForm({
        resolver: yupResolver(NewUserSchema),
        defaultValues:{
            amount: formatter.format(order.transaction_type === "sell" ? order.amount : order.settlement_amount),
            password: '',
            rate:1,
            processing_charges: 0,
            settlement_charges: 0
        }
    });

    const {
        reset,
        watch,
        control,
        setValue,
        handleSubmit,
        formState: { isSubmitting },
    } = methods;

    const values = watch();

    const onSubmit = async () => {
        try {
            
            let amt = String(values.amount);
            amt = amt.replace(",","");

            const formData = new FormData()
            formData.append('order_id', order.order_id);
            formData.append('password', values.password)
            formData.append('amount', amt);
            formData.append('rate', values.rate);
            formData.append('processing_charges', values.processing_charges);
            formData.append('settlement_charges', values.settlement_charges);
            
            // eslint-disable-next-line guard-for-in, no-restricted-syntax
            for(const i in state.files){
                formData.append(`userfile${i}`, state.files[i]);
            }

            let ret = {};
            if(action === "payment_received"){
               ret = await axiosInstance.post(CRUD.order.confirmPayment,formData);
            } else if(action === "complete"){
                ret = await axiosInstance.post(CRUD.order.completeOrder,formData);
            }
            else if(action === "hold"){
                ret = await axiosInstance.post(CRUD.order.doHold,formData);
            }
            
            reset();
            enqueueSnackbar('Action completed!');
            handleComplete(ret.data);
        } catch (error) {
            enqueueSnackbar(getErrorMessage(error));
        }
    };

    return <Modal
        open
        onClose={(e,r)=>{
            if(r &&r  === "backdropClick") return;
            handleClose();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        hideBackdrop={false}
        onBackdropClick={()=> true}
    >
        <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">{title} </Typography>

            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>

                <Card sx={{ p: 3, mt:5}}>
                    <Box  >
                        <FormControl htmlFor="simpl2" sx={{mb:3}}> 
                            <p> Upload proof of transaction </p> 
                            <input style={{marginTop:5}} type='file' 
                                    multiple accept='image/*' name="userfile"
                                    onChange={(e)=> setState({...state, files:e.target.files})}
                                />
                        </FormControl>

                        {/* {action === "payment_received" && order.transaction_type === "sell" &&
                            <p style={{fontSize:14,marginBottom:15, color:"orangered"}}>
                            Difference between final amount and {order.transaction_type === "sell" ? "order" : "settlement"} amount 
                            ({order.transaction_type === "sell" ? `$${fNumber(order.amount)}` : `${order.currency}${fNumber(order.settlement_amount)}`}) must not exceed 5%</p>
                            } */}
                        
                        {action === "payment_received" && <RHFTextField name="amount" 
                                                        label={`Final amount received (${order.transaction_type === "sell" ? '$' : order.currency})`} 
                                                        sx={{mb:3}} required
                                                        disabled={order.transaction_type === "buy"}
                                                        onBlur={(e)=> {
                                                            let val = String(e.target.value);
                                                            val = val.replace(",","");
                                                            setValue("amount",formatter.format(parseFloat(val)));
                                                        }}
                                                        />}

                        {action === "complete" && <p style={{fontSize:12,marginTop:15}}>Transaction fees incurred while trading coin</p>  }
                        {action === "complete" && <RHFTextField name="processing_charges" label="Processing charges($)" required sx={{mt:2,mb:3}} />  }
                        
                        {action === "complete" && <RHFTextField name="settlement_charges" label="Settlement charges(NGN)" required sx={{mb:3}} />  }

                        {action === "complete" && <RHFTextField name="rate" label="Exchange rate (per $)" sx={{mt:1,mb:2}} required />  }
                        {action === "complete" && <p style={{fontSize:12, marginBottom:5}}>Gross amount : NGN{formatter.format(values.rate * (order.final_amount - values.processing_charges))}</p>  }
                        {action === "complete" && <p style={{fontSize:12,marginBottom:20}}>Net amount : NGN{formatter.format((values.rate - buyRate) * (order.final_amount - values.processing_charges))}</p>  }

                        <p>Confirm password</p>
                        <RHFTextField name="password" label="Enter password" type="password" sx={{mt:1}} />  
                        
                    </Box>
                    <br/>
                    <Stack alignItems="flex-end" sx={{ mt: 3 }}>
                        <div>
                            <Button variant='text' sx={{mr:5}} onClick={handleClose}>
                                close
                            </Button>
                            <LoadingButton type="submit" size="large" 
                                disabled={action === "complete" && values.rate < buyRate}
                                variant="contained" loading={isSubmitting}>
                                Continue
                            </LoadingButton>
                        </div>
                    </Stack>
                </Card>
            </FormProvider>
        </Box>
    </Modal>

}
