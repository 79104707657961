import React from 'react';
import PropTypes from 'prop-types';
import { Card, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';
import { fNumber } from 'utils/formatNumber';
import { fDateTime } from 'utils/formatTime';
import Iconify from 'components/Iconify';
import Label from 'components/Label';
import { useTheme } from '@mui/material/styles';

const OderDetails = ({order,bank}) => {
    const theme = useTheme();
    
    let color = (order.status === 'cancelled' && 'error') || 'secondary';
        if(order.status === "completed"){
            color = 'success';
        } else if(order.status === "processing"){
            color = 'primary';
        }
        else if(order.status === "submitted"){
            color = 'warning';
        }
    return <>
        <Card sx={{padding:3, mt:3,mb:3}}>
            <Typography variat="h6" component="p" sx={{mb:3}}>
                Assigned to
            </Typography>
            <Iconify icon={'ph:user-thin'} size="small" sx={{width:25,height:25, mr:3,position:'relative',top:2}} />
            {order.staff_name} 
            
                <Label sx={{ml:3}}
                    variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                    color={color} >
                    {String(order.status).toLocaleUpperCase()}
                </Label>
        </Card>

        <Card sx={{padding:3, mb:3}}>
            <Typography variant="h6" component="p" sx={{mb:3}}>
            Payment details
            </Typography>
            <Typography variant="subtitle2" component="p" color="textSecondary">
            {bank?.bank_name}
            </Typography>
            <Typography variant="subheading" component="p" color="textSecondary">
            Account name: {bank?.account_name}
            </Typography>
            <Typography variant="subheading" component="p" color="textSecondary">
            Account number: {bank?.account_number}
            </Typography>
        </Card>
        <Card sx={{padding:3}}>
            <Typography variant="h6" component="p" sx={{mb:3}}>
                Order details
            </Typography>
            <TableContainer >
                <Table>
                    <TableBody>
                        <TableRow hover tabIndex={-1}  >
                            <TableCell >
                                <Typography variant="subtitle2" noWrap>
                                    User
                                </Typography>
                            </TableCell>
                            <TableCell >
                                <Typography variant="subtitle2" noWrap>
                                    <Iconify icon={'ph:user-thin'} size="small" sx={{width:18,height:18,mr:1, mt:1}} />
                                    {order.first_name} {order.last_name}
                                </Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow hover tabIndex={-1}  >
                            <TableCell >
                                <Typography variant="subtitle2" noWrap>
                                    Order I.D
                                </Typography>
                            </TableCell>
                            <TableCell >
                                <Typography variant="subtitle2" noWrap>
                                    #{String(order.order_id).toLocaleUpperCase()}
                                </Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow hover tabIndex={-1}  >
                                <TableCell >
                                    <Typography variant="subtitle2" noWrap>
                                        Amount
                                    </Typography>
                                </TableCell>
                                <TableCell >
                                    <Typography variant="subtitle2" noWrap>
                                        <span style={order.amount !== order.final_amount && order.final_amount !== 0
                                                ? {color:"orangered",textDecoration:"line-through"}
                                                : {}}>
                                            ${fNumber(order.amount)}
                                        </span> {" "}
                                        {order.amount !== order.final_amount && order.final_amount !== 0 ? ` $${fNumber(order.final_amount)}` : ""}
                                    </Typography>
                                </TableCell>
                        </TableRow>
                        <TableRow hover tabIndex={-1}  >
                            <TableCell >
                                <Typography variant="subtitle2" noWrap>
                                    On hold
                                </Typography>
                            </TableCell>
                            <TableCell >
                                <Typography variant="subtitle2" noWrap>
                                    <Label
                                        variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                                        color={order.on_hold === true ? 'warning' : 'secondary'} >
                                        {order.on_hold === true ? 'Yes' : 'No'}
                                    </Label>
                                </Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow hover tabIndex={-1}  >
                            <TableCell >
                                <Typography variant="subtitle2" noWrap>
                                    Payment status
                                </Typography>
                            </TableCell>
                            <TableCell >
                                <Typography variant="subtitle2" noWrap>
                                    <Label
                                        variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                                        color={order.payment_received === true ? 'success' : 'warning'} >
                                        {order.payment_received === true ? 'Received' : 'Pending'}
                                    </Label>
                                </Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow hover tabIndex={-1}  >
                            <TableCell >
                                <Typography variant="subtitle2" noWrap>
                                    Transaction type
                                </Typography>
                            </TableCell>
                            <TableCell >
                                <Typography variant="subtitle2" noWrap>
                                    {order.transaction_type}
                                </Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow hover tabIndex={-1}  >
                            <TableCell >
                                <Typography variant="subtitle2" noWrap>
                                    Exchange rate
                                </Typography>
                            </TableCell>
                            <TableCell >
                                <Typography variant="subtitle2" noWrap>
                                    {order.exchange_rate}
                                </Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow hover tabIndex={-1}  >
                            <TableCell >
                                <Typography variant="subtitle2" noWrap>
                                    Total Quantity
                                </Typography>
                            </TableCell>
                            <TableCell >
                                <Typography variant="subtitle2" noWrap>
                                {order.total_quantity}({String(order.coin_id).toLocaleUpperCase()})
                                </Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow hover tabIndex={-1}  >
                            <TableCell >
                                <Typography variant="subtitle2" noWrap>
                                    Coin market price
                                </Typography>
                            </TableCell>
                            <TableCell >
                                <Typography variant="subtitle2" noWrap>
                                ${fNumber(order.coin_usd_price)}
                                </Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow hover tabIndex={-1}  >
                            <TableCell >
                                <Typography variant="subtitle2" noWrap>
                                 Settlement amount
                                </Typography>
                            </TableCell>
                            <TableCell >
                                <Typography variant="subtitle2" noWrap>
                                    <span style={order.settlement_amount !== order.final_settlement_amount && order.final_settlement_amount !== 0
                                            ? {color:"orangered",textDecoration:"line-through"}
                                            : {}}>
                                        {order.currency}{fNumber(order.settlement_amount)}
                                    </span> {" "}
                                {/* {order.transaction_type ==="sell" ? order.currency : "$"}{fNumber(order.settlement_amount)} */}
                                {order.settlement_amount !== order.final_settlement_amount && order.final_settlement_amount !== 0 
                                    ? `${order.currency}${fNumber(order.final_settlement_amount)}` : ""}
                                </Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow hover tabIndex={-1}  >
                            <TableCell >
                                <Typography variant="subtitle2" noWrap>
                                    Settlement wallet address
                                </Typography>
                            </TableCell>
                            <TableCell >
                                <Typography variant="subtitle2" noWrap>
                                    {order?.settlement_wallet_address}
                                </Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow hover tabIndex={-1}  >
                            <TableCell >
                                <Typography variant="subtitle2" noWrap>
                                    Payment wallet address
                                </Typography>
                            </TableCell>
                            <TableCell >
                                <Typography variant="subtitle2" noWrap>
                                    {order?.payment_wallet_address}
                                </Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow hover tabIndex={-1}  >
                            <TableCell >
                                <Typography variant="subtitle2" noWrap>
                                    Payment hint
                                </Typography>
                            </TableCell>
                            <TableCell >
                                <Typography variant="subtitle2" noWrap>
                                    {order?.payment_hint}
                                </Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow hover tabIndex={-1}  >
                            <TableCell >
                                <Typography variant="subtitle2" noWrap>
                                    Transaction Ref (supplied by user)
                                </Typography>
                            </TableCell>
                            <TableCell >
                                <Typography variant="subtitle2" noWrap>
                                    {order?.user_tranx_id}
                                </Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow hover tabIndex={-1}  >
                            <TableCell >
                                <Typography variant="subtitle2" noWrap>
                                    Created at
                                </Typography>
                            </TableCell>
                            <TableCell >
                                <Typography variant="subtitle2" noWrap>
                                    {fDateTime(order.created_at)}
                                </Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow hover tabIndex={-1}  >
                            <TableCell >
                                <Typography variant="subtitle2" noWrap>
                                    Updated at
                                </Typography>
                            </TableCell>
                            <TableCell >
                                <Typography variant="subtitle2" noWrap>
                                    {fDateTime(order.updated_at)}
                                </Typography>
                            </TableCell>
                        </TableRow>
                      
                    </TableBody>
                </Table>
            </TableContainer>
        </Card>
        </>
};

OderDetails.propTypes = {
    order: PropTypes.object,
    bank: PropTypes.object,
}
export default OderDetails;