/* eslint-disable import/order */
import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
// import MainLayout from '../layouts/main';
// eslint-disable-next-line import/no-unresolved
import AdminUserList from 'pages/dashboard/AdminUserList';
import SupportAgents from 'pages/dashboard/SupportAgents';
import CryptoList from 'pages/dashboard/CryptoList';
import CryptoRates from 'pages/dashboard/CryptoExchangeRates';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';
import ManageOrders from 'pages/dashboard/ManageOrders';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  console.log(pathname);
  console.log(`Loadable HERE ${pathname}`);

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        // { path: 'verify', element: <VerifyCode /> },
      ],
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [ 

        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },

        { path: 'overview', element: <AppOverview /> },
        { path: 'users-overview', element: <UsersOverview /> },
        { path: 'transaction-overview', element: <TransactionOverview /> },

        { path: 'admin', element: <AdminUserList /> },
        { path: 'support-agents', element: <SupportAgents /> },
        { path: 'featured-posts', element: <FeaturedBlogPosts /> }, 
        
        { path: 'variables', element: <SystemVariables /> },

        { path: 'activity-log', element: <ActivityLog /> },
        

        {
          path: 'collections',
          children: [
            { element: <Navigate to="/dashboard/collections" replace />, index: true },
            { path: 'bank-accounts', element: <CollectionBankAccounts /> },
            { path: 'incoming', element: <IncomingPayments /> },
            { path: 'providus', element: <ProvidusBankSettlement /> },
          ],
        },

        {
          path: 'trading',
          children: [
            { element: <Navigate to="/dashboard/trading" replace />, index: true },
            { path: 'crpto-list', element: <CryptoList /> },  
            { path: 'crpto-rates', element: <CryptoRates /> },
            { path: 'wallet-address', element: <CryptoWalletAddress /> },
            { path: 'sales-support', element: <GiftcardContact /> }, 
          ],
        },

        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/list" replace />, index: true },
            { path: 'list', element: <UserList /> },
            { path: 'wallet', element: <UserWallet /> },
          ],
        },
        {
          path: 'order',
          children: [
            { element: <Navigate to="/dashboard/order" replace />, index: true },
            { path: 'list', element: <ManageOrders /> },
            { path: 'giftcard', element: <GiftcardOrders /> },
            // { path: 'new-post', element: <BlogNewPost /> },
          ],
        },
        {
          path: 'rewards',
          children: [
            { element: <Navigate to="/dashboard/rewards" replace />, index: true },
            { path: 'referral-ranking', element: <ReferralRanking /> },
            { path: 'monthly-bonus', element: <MonthlyPendingBonus /> },
          ],
        },
        {
          path: 'database',
          children: [
            { element: <Navigate to="/dashboard/database" replace />, index: true },
            { path: 'transactions', element: <TransactionsLog /> },
            { path: 'bills-payment', element: <BillsPaymentHistory /> },
            { path: 'paystack', element: <PaystackTransactions /> },
          ],  
        },
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        // { path: 'coming-soon', element: <ComingSoon /> },
        // { path: 'maintenance', element: <Maintenance /> },
        // { path: 'pricing', element: <Pricing /> },
        // { path: 'payment', element: <Payment /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: '/',
      element:  <GuestGuard> <Login />  </GuestGuard>,
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
// const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));
// Dashboard
const TransactionsLog = Loadable(lazy(() => import('../pages/dashboard/TransactionsLog')));
const CryptoWalletAddress = Loadable(lazy(() => import('../pages/dashboard/CryptoWalletAddress')));
const FeaturedBlogPosts = Loadable(lazy(() => import('../pages/dashboard/FeaturedBlogPosts')));
const GiftcardContact = Loadable(lazy(() => import('../pages/dashboard/GiftcardContact')));
const SystemVariables = Loadable(lazy(() => import('../pages/dashboard/SystemVariables')));
const UserWallet = Loadable(lazy(() => import('../pages/dashboard/UserWallet')));
// const ManageOrders = Loadable(lazy(() => import('../pages/dashboard/ManageOrders')));
const GiftcardOrders = Loadable(lazy(() => import('../pages/dashboard/GiftcardOrders')));
const ReferralRanking = Loadable(lazy(() => import('../pages/dashboard/ReferralRanking')));
const MonthlyPendingBonus = Loadable(lazy(() => import('../pages/dashboard/MonthlyPendingBonus')));
const BillsPaymentHistory = Loadable(lazy(() => import('../pages/dashboard/BillsPaymentHistory')));
const PaystackTransactions = Loadable(lazy(() => import('../pages/dashboard/PaystackTransactions')));
const ProvidusBankSettlement = Loadable(lazy(() => import('../pages/dashboard/ProvidusBankSettlement')));
const CollectionBankAccounts = Loadable(lazy(() => import('../pages/dashboard/CollectionBankAccounts')));
const IncomingPayments = Loadable(lazy(() => import('../pages/dashboard/IncomingPayments')));
const ActivityLog = Loadable(lazy(() => import('../pages/dashboard/ActivityLog')));
const UserList = Loadable(lazy(() => import('../pages/dashboard/UserList')));
const AppOverview = Loadable(lazy(() => import('../pages/dashboard/AppOverview')));
const UsersOverview = Loadable(lazy(() => import('../pages/dashboard/UserOverview')));
const TransactionOverview = Loadable(lazy(() => import('../pages/dashboard/TransactionOverview')));

// Main

const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
