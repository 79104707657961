/* eslint-disable import/order */
/* eslint-disable camelcase */
import { sentenceCase } from 'change-case';
import { useState, useCallback, useEffect } from 'react';
// @mui
import {
  Card,
  Table,
  Avatar,
  Button,
  Container,
  Typography,
  Box,
  CircularProgress,
  Chip
} from '@mui/material';
// routes
import axios, { getErrorMessage } from 'utils/axios';
import useIsMountedRef from 'hooks/useIsMountedRef';
import CRUD from 'utils/Crud';
import { fDate, fDateTime } from 'utils/formatTime';
import { PATH_DASHBOARD } from '../../routes/paths';
// hooks
import useSettings from '../../hooks/useSettings';

// components
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
// sections
import Swal from 'sweetalert2'
import AppColors from "utils/AppColors";
import SetCryptoRateModal from 'sections/@dashboard/crypto/SetExchangeRateModal';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { fNumber } from 'utils/formatNumber';
import CustomGridToolbar from 'components/CustomGridToolbar';

// ----------------------------------------------------------------------



export default function CryptoExchangeRates() {
  // const theme = useTheme();
  const { themeStretch } = useSettings();
  const isMountedRef = useIsMountedRef();
  const [modal, setModal] = useState({open:false, user:{}});
  // const [updateModal, setUpdateModal] = useState({open:false, range:{}});
  const [dataList, setdataList] = useState([]);
  const [state,setState] = useState({loading:false,loadComplete:false,error:null, isDeleting: false});
  const [filterModel, setFilterModel] = useState({
    items: [
      {
        columnField: 'rating',
        operatorValue: '>',
        value: '2.5',
      },
    ],
  });


  const getInitData = useCallback(async () => {
    
    setState({...state,loading:true,loadComplete:false,error:null});

    try {
      const response = await axios.get(CRUD.cryptoRates);

      if (isMountedRef.current) {
        setdataList(response.data);
      }
      setState({...state,loading:false,loadComplete:true,error:null})
    } catch (error) {
      // console.error(error);
      setState({...state,loading:false,loadComplete:false,error: getErrorMessage(error)})
    }
  }, [isMountedRef]);

  useEffect(() => {
    getInitData();
  }, [getInitData]);

  return (
    <Page title="Manage Coins">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="Exchange Rates"
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            { name: 'Cryptocurrency' },
            { name: 'Exchange Rates' },
          ]}
          action={
            <Button sx={{ml:2}}
              variant="contained"
              component={Button}
              onClick={()=>{
                setModal({open:true, isEdit: false});
              }}
              startIcon={<Iconify icon={'eva:plus-fill'} />}
            >
              Add Range
            </Button>
          }
        />

        {state.loading && (
          <Card>
             <Box padding={10} display={"flex"} justifyContent={"center"}>
              <CircularProgress />
            </Box>
          </Card>
        )}

        {!state.loading && state.error !=null && (
          <Card>
            <Box padding={10} display={"flex"} justifyContent={"center"}>
            <p>Error {state.error} </p>
            </Box>
          </Card>
        )}

        {state.loadComplete && dataList.length < 1 && (
          <Card>
            <Box padding={10} display={"flex"} justifyContent={"center"}>
            <p> There's nothing here yet </p>
            </Box>
          </Card>
        )}

        {state.loadComplete && <Card>

          <Box sx={{ height: 600, width: '100%' }}>
            <DataGrid
              rows={dataList}
              columns={[
                {
                  field: 'name',
                  headerName: 'Name',
                  width: 220,
                  editable: false,
                  renderCell: (params) => (
                    <>
                      <Avatar alt={params.value} src={params.row.image_url} sx={{ mr: 2 }} onClick={()=> console.log(params.row)} />
                      <Typography variant="subtitle2" noWrap>
                        {params.row.name} ({params.row.coin_id})
                      </Typography>
                    </>
                  ),
                  valueGetter: (params) => params.row.name
                },
              
                {
                  field: 'start_amount',
                  headerName: 'From Amount ($)',
                  width: 140,
                  editable: false,
                  valueGetter: (params) => `$${params.value}`,
                  // valueGetter: (params) => params.row.start_amount
                },
                {
                  field: 'end_amount',
                  headerName: 'To ($)',
                  width: 120,
                  editable: false,
                  valueGetter: (params) => `$${fNumber(params.value)}`,
                  // valueGetter: (params) => params.row.end_amount
                },
                {
                  field: 'rate',
                  headerName: 'Rate (NGN)',
                  width: 120,
                  editable: false,
                  valueGetter: (params) => `${params.row.currency.toString()}${fNumber(params.value)}`,
                  // valueGetter: (params) => params.row.rate
                },
                {
                  field: 'transaction_type',
                  headerName: 'Type',
                  width: 120,
                  editable: false,
                  renderCell: (params) => (
                    <Chip 
                      variant='outlined'
                      color={params.value === "sell" ? "primary" : 'secondary'}
                      icon={<Iconify icon={ params.value === "sell" ? 'material-symbols:sell-outline' : 'icon-park-outline:buy'} />}
                      label={params.value.toUpperCase()} />
                  ),
                  valueGetter: (params) => params.row.transaction_type
                },
                {
                  field: 'updated_at',
                  headerName: 'Updated at',
                  width: 180,
                  editable: false,
                  valueGetter: (params) => fDate(params.value),
                  // valueGetter: (params) => params.row.updated_at
                },
                {
                  // id: 2,
                  field: "actions",
                  type: "actions",
                  align: "right",
                  width: 280,
                  headerName: "Actions",
                  getActions: (params) => [
                    <Button 
                      key={2}
                      variant='outlined'
                      color="secondary"
                      onClick={()=> {
                        setModal({open:true, isEdit:true, range:params.row});
                      }}
                      startIcon={<Iconify icon={'eva:edit-fill'} /> }
                      >
                        Update
                    </Button>,
                    <Button 
                      key={3}
                      variant='outlined'
                      color="error"
                      onClick={() => removeRange(params.row)}
                      startIcon={<Iconify icon={'eva:trash-2-outline'} />}
                      >
                        Delete
                  </Button>
                  ],
                },
              
              ]}
              components={{ Toolbar: CustomGridToolbar }}
              pageSizeOptions={[50]}
              checkboxSelection
              disableRowSelectionOnClick
              // disableColumnFilter
              // disableColumnSelector
              // disableDensitySelector
              // slots={{ Toolbar: GridToolbar }}
              // componentsProps={{
              //   Toolbar: GridToolbar 
              // }}
              // filterModel={filterModel}
              // onFilterModelChange={(newFilterModel) => setFilterModel(newFilterModel)}
              // componentsProps={{
              //   toolbar: {
              //     showQuickFilter: true,
              //     quickFilterProps: { debounceMs: 500 },
              //   },
              // }}
              
            />
          </Box>

        </Card>}
      </Container>


     {modal.open &&  <SetCryptoRateModal
        open={modal.open}
        range={modal.range}
        handleClose={()=>{
          setModal({open:false});
        }}
        isEdit={modal.isEdit}
        handleComplete={()=>{
          setModal({open:false});
          getInitData();
        }}
         />}

    </Page>
  );


  function removeRange(row){
    Swal.fire({
        text: "Do you want to remove this range?",
        icon: 'warning',
        showCancelButton: true,
        // eslint-disable-next-line import/no-named-as-default-member
        confirmButtonColor: AppColors.DANGER,
        confirmButtonText: "Yes, contine",
        reverseButtons: true,
        showLoaderOnConfirm: true,
        allowOutsideClick: () => !Swal.isLoading(),
        preConfirm: async ()  =>  {
          try {
            await axios.delete(`${CRUD.cryptoRates}/${row.id}`);
            const data = dataList.filter(e=>e.id !== row.id);
            setdataList(data);
            return true;
          } catch (error) {
            Swal.showValidationMessage(
                `${getErrorMessage(error) || error.toString()}`
            );
            return false;
          }
        },
    }).then((result) => {
        if (result.value) {
            Swal.close();
        } 
    });

  }


}
