import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {

  const logo = (
    <Box sx={{ width: 80, height: 80, ...sx }}>
        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="100%" height="100%" style={{shapeRendering:"geometricPrecision", textRendering:"geometricPrecision", imageRendering:"optimizeQuality", fillRule:"evenodd", clipRule:"evenodd"}} >
          <g><path style={{opacity:0.962}} fill="#2e335d" d="M 72.5,29.5 C 77.5266,43.2145 76.1933,56.2145 68.5,68.5C 69.6108,70.5522 71.2775,71.8855 73.5,72.5C 74.8333,73.8333 76.1667,75.1667 77.5,76.5C 75.2433,76.9617 73.2433,77.9617 71.5,79.5C 68.3986,77.9016 65.7319,75.7349 63.5,73C 50.9936,74.1608 43.1602,68.6608 40,56.5C 39.2052,49.7823 39.3718,43.1157 40.5,36.5C 40.0431,35.7025 39.3764,35.3691 38.5,35.5C 42.7268,24.5479 50.7268,20.0479 62.5,22C 66.3003,23.9789 69.6336,26.4789 72.5,29.5 Z M 63.5,63.5 C 59.5345,67.4856 55.5345,67.4856 51.5,63.5C 49.7468,56.922 49.0802,50.422 49.5,44C 50.5762,39.4291 53.2428,36.2624 57.5,34.5C 62.4721,37.5295 65.1387,42.0295 65.5,48C 64.6258,53.0998 63.9591,58.2665 63.5,63.5 Z"/></g>
          <g><path style={{opacity:1}} fill="#2d335e" d="M 35.5,24.5 C 35.5,25.5 35.5,26.5 35.5,27.5C 27.5,27.5 19.5,27.5 11.5,27.5C 11.5,26.5 11.5,25.5 11.5,24.5C 19.5,23.1667 27.5,23.1667 35.5,24.5 Z"/></g>
          <g><path style={{opacity:0.071}} fill="#0b0b0b" d="M 11.5,24.5 C 11.5,25.5 11.5,26.5 11.5,27.5C 19.5,27.5 27.5,27.5 35.5,27.5C 35.5,26.5 35.5,25.5 35.5,24.5C 37.1958,24.6854 38.5291,25.5187 39.5,27C 39.3539,29.3708 38.3539,31.7042 36.5,34C 32.3333,34.1667 28.1667,34.3333 24,34.5C 19.0565,34.6119 14.2232,34.1119 9.5,33C 6.62648,29.3474 7.29314,26.5141 11.5,24.5 Z"/></g>
          <g><path style={{opacity:0.127}} fill="#0e0e0f" d="M 63.5,63.5 C 63.9591,58.2665 64.6258,53.0998 65.5,48C 65.1387,42.0295 62.4721,37.5295 57.5,34.5C 53.2428,36.2624 50.5762,39.4291 49.5,44C 49.0802,50.422 49.7468,56.922 51.5,63.5C 48.9877,61.5216 47.4877,58.8549 47,55.5C 46.3333,49.5 46.3333,43.5 47,37.5C 50.3384,27.1547 56.3384,25.1547 65,31.5C 68.8372,39.8807 69.5039,48.5474 67,57.5C 66.5762,60.0254 65.4095,62.0254 63.5,63.5 Z"/></g>
          <g><path style={{opacity:0.844}} fill="#2f3460" d="M 27.5,38.5 C 27.5,39.5 27.5,40.5 27.5,41.5C 19.5,41.5 11.5,41.5 3.5,41.5C 3.5,40.5 3.5,39.5 3.5,38.5C 11.5,37.1667 19.5,37.1667 27.5,38.5 Z"/></g>
          <g><path style={{opacity:0.074}} fill="#111111" d="M 3.5,38.5 C 3.5,39.5 3.5,40.5 3.5,41.5C 11.5,41.5 19.5,41.5 27.5,41.5C 27.5,40.5 27.5,39.5 27.5,38.5C 28.7498,38.5774 29.5831,39.244 30,40.5C 31.6629,48.3349 28.4962,51.3349 20.5,49.5C 14.5555,48.7213 8.72214,48.7213 3,49.5C 0.670391,46.5405 0.00372481,43.2072 1,39.5C 1.67076,38.7476 2.5041,38.4142 3.5,38.5 Z"/></g>
          <g><path style={{opacity:0.012}} fill="#7c7c7c" d="M 77.5,40.5 C 77.8333,41.1667 78.1667,41.8333 78.5,42.5C 77.8826,42.6107 77.3826,42.944 77,43.5C 76.2599,42.2506 76.4265,41.2506 77.5,40.5 Z"/></g>
          <g><path style={{opacity:0.055}} fill="#1e1e1e" d="M 72.5,29.5 C 74.9341,32.7093 76.6008,36.376 77.5,40.5C 76.4265,41.2506 76.2599,42.2506 77,43.5C 77.3826,42.944 77.8826,42.6107 78.5,42.5C 79.6477,53.3824 77.981,63.3824 73.5,72.5C 71.2775,71.8855 69.6108,70.5522 68.5,68.5C 76.1933,56.2145 77.5266,43.2145 72.5,29.5 Z"/></g>
          <g><path style={{opacity:1}} fill="#30355f" d="M 28.5,55.5 C 27.596,56.209 27.2627,57.209 27.5,58.5C 19.5,58.5 11.5,58.5 3.5,58.5C 3.5,57.5 3.5,56.5 3.5,55.5C 11.8333,54.1667 20.1667,54.1667 28.5,55.5 Z"/></g>
          <g><path style={{opacity:0.09}} fill="#0f0f0f" d="M 3.5,55.5 C 3.5,56.5 3.5,57.5 3.5,58.5C 11.5,58.5 19.5,58.5 27.5,58.5C 27.2627,57.209 27.596,56.209 28.5,55.5C 29.3636,56.6883 30.3636,57.8549 31.5,59C 31.4538,61.1022 30.4538,63.1022 28.5,65C 20.9983,66.1887 13.8316,66.6887 7,66.5C 3.10783,66.0967 0.607828,64.0967 -0.5,60.5C -0.5,60.1667 -0.5,59.8333 -0.5,59.5C 0.28997,58.3915 0.78997,57.0581 1,55.5C 1.76971,54.4019 2.60305,54.4019 3.5,55.5 Z"/></g>
          <g><path style={{opacity:0.117}} fill="#111112" d="M 38.5,35.5 C 39.3764,35.3691 40.0431,35.7025 40.5,36.5C 39.3718,43.1157 39.2052,49.7823 40,56.5C 43.1602,68.6608 50.9936,74.1608 63.5,73C 65.7319,75.7349 68.3986,77.9016 71.5,79.5C 73.2433,77.9617 75.2433,76.9617 77.5,76.5C 79.1639,85.6472 75.4972,88.4805 66.5,85C 64.0392,81.4392 60.8725,79.9392 57,80.5C 51.1475,79.4944 46.3142,76.8277 42.5,72.5C 42.0133,71.5266 41.5133,70.5266 41,69.5C 40.235,71.0657 40.4017,72.3991 41.5,73.5C 41.8186,75.7505 41.3186,77.7505 40,79.5C 31.3707,79.6062 22.5374,79.4396 13.5,79C 11.7139,78.8038 10.3805,77.9705 9.5,76.5C 10.083,74.452 10.083,72.6187 9.5,71C 10.5665,68.988 11.8998,68.488 13.5,69.5C 13.5,70.5 13.5,71.5 13.5,72.5C 21.5,72.5 29.5,72.5 37.5,72.5C 37.5,71.5 37.5,70.5 37.5,69.5C 38.3764,69.6309 39.0431,69.2975 39.5,68.5C 37.0149,62.9653 36.0149,57.132 36.5,51C 36.2849,45.6537 36.9516,40.487 38.5,35.5 Z"/></g>
          <g><path style={{opacity:1}} fill="#2d335e" d="M 37.5,69.5 C 37.5,70.5 37.5,71.5 37.5,72.5C 29.5,72.5 21.5,72.5 13.5,72.5C 13.5,71.5 13.5,70.5 13.5,69.5C 21.5,68.1667 29.5,68.1667 37.5,69.5 Z"/></g>
          <g><path style={{opacity:0.004}} fill="#e8e8e8" d="M 42.5,72.5 C 41.8333,72.5 41.5,72.8333 41.5,73.5C 40.4017,72.3991 40.235,71.0657 41,69.5C 41.5133,70.5266 42.0133,71.5266 42.5,72.5 Z"/></g>
        </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
