import PropTypes from 'prop-types';
// hooks
import useAuth from '../hooks/useAuth';
// utils
import createAvatar from '../utils/createAvatar';
//
import Avatar from './Avatar';

// ----------------------------------------------------------------------

UserAvatar.propTypes = {
  name: PropTypes.string.isRequired,
  imageURL: PropTypes.string.isRequired,
};

export default function UserAvatar({imageURL,name, ...other }) {
  return (
    <Avatar
      src={imageURL}
      alt={name}
      color={imageURL ? 'default' : createAvatar(name).color}
      {...other}
    >
      {createAvatar(name).name}
    </Avatar>
  );
}
