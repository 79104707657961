import axios from 'axios';
// config
import { HOST_API } from '../config';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({
  baseURL: HOST_API,
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export const HTTP_VALIDATION_ERROR = 412;

export function hasError(ApiResponse){
    if(ApiResponse.messages){
        // const errors = ApiResponse.response.data.messages;
        if(ApiResponse.status === HTTP_VALIDATION_ERROR){
            return true;
        }
    }
    return false;
}


export function getErrorMessage(ApiResponse){
  const {messages,status} = ApiResponse;
      
  if(messages && status === HTTP_VALIDATION_ERROR){
    // console.log(messages.error);
      return messages.error;
  }

  return ApiResponse.toString();
}

export function getErrorMessages(ApiResponse){
  if(ApiResponse.response){
      const {messages,error} = ApiResponse.response.data;
      if(error === "E754-00"){
          alert("Please login again")
      }
      if(typeof messages === "object" && Object.keys(messages).length){
          return messages;
      }
  }
  return {};
}

export default axiosInstance;
