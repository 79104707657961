/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Modal, Box, Typography, Card, Stack, FormControlLabel, Switch, TextField } from '@mui/material';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css'
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import axiosInstance, { getErrorMessage } from 'utils/axios';
import { FormProvider, RHFSelect, RHFTextField, RHFUploadAvatar } from 'components/hook-form';
import { LoadingButton } from '@mui/lab';
import CRUD from 'utils/Crud';
import { fData } from 'utils/formatNumber';
import { TextareaAutosize } from '@mui/base';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '2px solid whitesmoke',
    boxShadow: 24,
    p: 1,
};


const NewUserSchema = Yup.object().shape({
    remark: Yup.string(),
    password: Yup.string().required('Field is required'),
    action: Yup.string().required('Field is required'),
});

ReviewKYCModal.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func,
    handleComplete: PropTypes.func,
    user: PropTypes.object.isRequired
}

export default function ReviewKYCModal({ open, handleClose,handleComplete, user}) {

    const { enqueueSnackbar } = useSnackbar();

    const methods = useForm({
        resolver: yupResolver(NewUserSchema),
        defaultValues:{
            action:"",
            password:'',
            remark:''
        },
    });

    const {
        reset,
        watch,
        control,
        setValue,
        handleSubmit,
        formState: { isSubmitting },
    } = methods;

    const values = watch();

    const onSubmit = async () => {
        try {
            
            values.user_id = user.user_id;
            await axiosInstance.post(CRUD.user.submtReview,values);
            enqueueSnackbar('Action completed successfully!');
            reset();
            handleComplete(true);
        } catch (error) {
            enqueueSnackbar(getErrorMessage(error));
        }
    };

    return <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        hideBackdrop={false}
    >
        <Box sx={style}>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>

                <Card sx={{ p: 3,}}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Review account
                    </Typography>

                    <Box  sx={{mt:4}}>

                        
                        <p style={{marginTop:10}}>Action type</p>
                        <RHFSelect name="action" >
                            <option value="">Select option</option>
                            {['Reject','Approve'].map((option) => (
                            <option key={option} value={option}>
                                {option}
                            </option>
                            ))}
                        </RHFSelect>

                        {values.action === "Reject" && (
                            <p style={{marginTop:20}}>Remark</p>
                        )}
                        {values.action === "Reject" && (
                            <p style={{color: 'dimgray'}}>Write a short reason for rejection to be sent to user </p>
                        )}
                        {values.action === "Reject" && (
                            <RHFTextField sx={{mt:1}} name="remark" required multiline minRows={3} maxRows={6} /> 
                        )} 

                        <p style={{marginTop:30}}>Confirm password</p>
                        <p style={{color:'orangered', fontSize:12}}>Attn: Please ensure full compliance before approving of account. You will be held liablity in the event that you approve a bad actor with false information.</p>
                        
                        <RHFTextField name="password" label="Password" type="password" sx={{mt:1}} /> 
                    </Box>
                    <br/>
                    <Stack alignItems="flex-end" sx={{ mt: 3 }}>
                        <LoadingButton type="submit" variant="contained" size='large' loading={isSubmitting}>
                        Submit review
                        </LoadingButton>
                    </Stack>
                </Card>

            </FormProvider>
        </Box>
    </Modal>
}
