// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify')
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  page404: '/404',
  page500: '/500',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    overview: path(ROOTS_DASHBOARD,'/overview'),
    usersOverview: path(ROOTS_DASHBOARD, '/users-overview'),
    transactionOverview: path(ROOTS_DASHBOARD, '/transaction-overview'),
  },
  featuredPosts: path(ROOTS_DASHBOARD, '/featured-posts'),

  activityLog: path(ROOTS_DASHBOARD, '/activity-log'),
  
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    wallet: path(ROOTS_DASHBOARD, '/user/wallet'),
    bannedAccounts: path(ROOTS_DASHBOARD, '/user/banned'),

    list: path(ROOTS_DASHBOARD, '/user/list'),
    newUser: path(ROOTS_DASHBOARD, '/user/new'),
    editById: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
    account: path(ROOTS_DASHBOARD, '/user/account')
  },
  order: {
    root: path(ROOTS_DASHBOARD, '/order'),
    list: path(ROOTS_DASHBOARD, '/order/list'),
    giftcard: path(ROOTS_DASHBOARD, '/order/giftcard'),
  },
  trading: {
    root: path(ROOTS_DASHBOARD, '/trading'),
    list: path(ROOTS_DASHBOARD, '/trading/crpto-list'),
    rates: path(ROOTS_DASHBOARD, '/trading/crpto-rates'),
    wallet: path(ROOTS_DASHBOARD, '/trading/wallet-address'),
    salesSupport: path(ROOTS_DASHBOARD, '/trading/sales-support'),
  },
  rewards: {
    root: path(ROOTS_DASHBOARD, '/rewards'),
    referralRanking: path(ROOTS_DASHBOARD, '/rewards/referral-ranking'),
    monthlyBonus: path(ROOTS_DASHBOARD, '/rewards/monthly-bonus'),
  },
  collections: {
    root: path(ROOTS_DASHBOARD, '/collections'),
    bankAccount: path(ROOTS_DASHBOARD, '/collections/bank-accounts'),
    imcoming: path(ROOTS_DASHBOARD, '/collections/incoming'),
    providusSettlement: path(ROOTS_DASHBOARD, '/collections/providus'),
  },
  system: {
    adminAccount: path(ROOTS_DASHBOARD, '/admin'),
    supportAgent: path(ROOTS_DASHBOARD, '/support-agents'),
    crpto: {
        list: path(ROOTS_DASHBOARD, '/crpto-list'),
        rates: path(ROOTS_DASHBOARD, '/crpto-rates'),
        wallet: path(ROOTS_DASHBOARD, '/wallet-address'),
    }, 
    configurations: {
      // giftcardVendors: path(ROOTS_DASHBOARD, '/giftcard-vendor'),
      // coins: path(ROOTS_DASHBOARD, '/blog'),
      variables: path(ROOTS_DASHBOARD, '/variables'),
    },
  },
  database: {
    root: path(ROOTS_DASHBOARD, '/database'),
    transactions: path(ROOTS_DASHBOARD, '/database/transactions'),
    billsPayment: path(ROOTS_DASHBOARD, '/database/bills-payment'),
    paystack: path(ROOTS_DASHBOARD, '/database/paystack'),
    selector: path(ROOTS_DASHBOARD, '/database/selector'),
    // virtualAccounts: path(ROOTS_DASHBOARD, '/database/virtual-account'),
  }
};