// routes
import axios from 'utils/axios';
import { useEffect } from 'react';
import { setSession } from 'utils/jwt';
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import Settings from './components/settings';
import RtlLayout from './components/RtlLayout';
import { ChartStyle } from './components/chart';
import ScrollToTop from './components/ScrollToTop';
import { ProgressBarStyle } from './components/ProgressBar';
import NotistackProvider from './components/NotistackProvider';
import ThemeColorPresets from './components/ThemeColorPresets';
import ThemeLocalization from './components/ThemeLocalization';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
// ----------------------------------------------------------------------

export default function App() {

  useEffect(()=>{
    // do token refresh
    setInterval(()=>{
      refreshToken();
    },1000 * 60 * 10);
  },[]);


  return (
    <ThemeProvider>
      <ThemeColorPresets>
        <ThemeLocalization>
          <RtlLayout>
            <NotistackProvider>
              <MotionLazyContainer>
                <ProgressBarStyle />
                <ChartStyle />
                <Settings />
                <ScrollToTop />
                <Router />
              </MotionLazyContainer>
            </NotistackProvider>
          </RtlLayout>
        </ThemeLocalization>
      </ThemeColorPresets>
    </ThemeProvider>
  );
}


async function refreshToken(){
  const token = window.sessionStorage.getItem('access_token');
  if(token != null){
    try {
      const response = await axios.get('/ping');
      // eslint-disable-next-line camelcase
      const { access_token } = response.data;
      setSession(access_token);
    } catch (err) { 
      // here
    }
  }
}