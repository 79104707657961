import PropTypes from 'prop-types';
// @mui
import { styled } from '@mui/material/styles';
import { Link, Card, Typography, CardHeader, Box, List,ListItem, ListItemText, Divider } from '@mui/material';
// components
import { fDateTime } from 'utils/formatTime';
import Iconify from '../../../components/Iconify';
import ProfileSettlementBanks from './ProfileSettlementBanks';

// ----------------------------------------------------------------------

const IconStyle = styled(Iconify)(({ theme }) => ({
  width: 20,
  height: 20,
  marginTop: 1,
  flexShrink: 0,
  marginRight: theme.spacing(2),
}));

// ----------------------------------------------------------------------

ProfileGeneral.propTypes = {
  devices: PropTypes.array.isRequired,
  settlementBanks: PropTypes.array.isRequired,
};

export default function ProfileGeneral({ devices,settlementBanks }) {

  // console.log("deivce",devices)
  return (
    <Card>
      <CardHeader title="User devices" />

      <Box sx={{p:3}}>
        <List>
          {devices.map((e,i)=><>
            <ListItem key={i}>
              <ListItemText
                primary={e.device_id}
                secondary={`${e.platform} - ${e.name}`}
              />
            </ListItem>
            {i + 1 < devices.length && (
              <Divider key={99} component={"li"} />
            )}
            </>)}
          
        </List>
      </Box>
      
      <br/>
      <Divider />
      <CardHeader title="Bank accounts" />
      <Box sx={{p:3}}>
      
        <List>
          {settlementBanks.map((e,i)=><>
            <ListItem key={i}>
              <ListItemText
                primary={e.bank_name}
                secondary={`ID: ${e.id} - ${e.account_number} - ${e.account_name}`}
              />
            </ListItem>
            {i + 1 < settlementBanks.length && (
              <Divider component={"li"} />
            )}
            </>)}
          
        </List>
        {settlementBanks.length < 1 && <p>  No records found  </p>}
      </Box>
      <br/>
    </Card>
  );
}
