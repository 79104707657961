const CRUD = {
    overview: {
        ordersOverview: "/overview_order",
        usersOverview: "/overview_users",
        transactionOverview: "/overview_transactions",
    },
    admin: {
        list: "/admins",
        add: "/admins",
        update: "/admins/update",
    },
    supportAgent: "/support_agents",
    activities: "/activities",
    cryptoAssets: "/cryto_asset",
    cryptoRates: "/cryto_rate",
    paymentWalletAddress: "/wallet_address",
    featuredPosts: "/featured_posts",
    giftcardVendor: "/giftcard_vendor",
    systemVariables: "/sys_variables",
    user: {
        list: "/users",
        update: "/user/update",
        search: "/users/find",
        referralList: "/user/referrals",
        details: "/user/details",
        submtReview: "/user/submit_review",
        security_changes: "/user/update_setting",
        wallet: "/users/wallet",
    },
    order: {
        list: "/orders",
        search: "/orders/find",
        details: "/orders/details",
        confirmPayment: "/orders/confirm_payment",
        completeOrder: "/orders/complete",
        doHold: "/orders/hold",
        giftcardList: "/giftcard_orders",
        giftcardSearch: "/giftcard_orders/find",
        giftcardOrderDetails: "/giftcard_orders/details",
        giftcardOrderComplete: "/giftcard_orders/complete",
        giftcardOrderReject: "/giftcard_orders/reject",
    },
    rewards: {
        referralRanking: "/referral_ranking",
        monthlyBonus: "/monthly_bonus",
    },
    database: {
        transactions: "/transactions",
        searchTransactions: "/transactions/find",
        searchBills: "/bills_payment/find",
        bills: "/bills_payment",
        paystackTransfer: "/paystack_transfers",
        searchPaystackTransfer: "/paystack_transfers/find",
    },
    collections:{
        bankAccount: {
            list: "/virtual_accounts",
            saveBank: "/virtual_account",
            updateBank: "/virtual_account/update",
            bankList: "/banks",
            resolveBankAccount: "/bank/resolve_account",
            saveOtherBanks: "/bank/save_other_bank",
        },
        providusSettlement: "/providus_bank_settlement",
        searchProvidusSettlement: "/providus_bank_settlement/find",
        incomingPayment: "/incoming_payment",
        searchIncomingPayment: "/incoming_payment/find",
    }
}

export default CRUD;