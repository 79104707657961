/* eslint-disable import/order */
import PropTypes from 'prop-types';
// @mui
import { Box, Grid, Card, Link, Avatar, IconButton, Typography, InputAdornment, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
// components
import Iconify from '../../../../components/Iconify';
import InputStyle from '../../../../components/InputStyle';
import { useEffect, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import useIsMountedRef from 'hooks/useIsMountedRef';
import axios, { getErrorMessage } from 'utils/axios';
import CRUD from 'utils/Crud';
import { DataGrid } from '@mui/x-data-grid';
import { fDate, fDateTime } from 'utils/formatTime';
import { useSnackbar } from 'notistack';
// ----------------------------------------------------------------------


ReferralHistory.propTypes = {
  user: PropTypes.object,
};


export default function ReferralHistory({ user }) {

  const isMountedRef = useIsMountedRef();
  const [sort, setSort] = useState({field:'id', order:'asc'});
  const [data, setData] = useState({data:[],current_page:1,total_pages:null,total_size:0,page_size:0});
  const [state,setState] = useState({loading:false,loadComplete:false,error:null});
  const { enqueueSnackbar } = useSnackbar();


  // const updateData = (k, v) => setData((prev) => ({ ...prev, [k]: v }));
  
  useEffect(() => {
    getInitData();
  }, []);

  return (
    <Box sx={{ mt: 1 }}>
      <Card sx={{padding:3}}>
        <Typography variant="h4" sx={{ mb: 3 }}>
          Referral history
        </Typography>

          <Box sx={{display:"flex", alignContent:"right", justifyContent:"end", width:"100%", padding:2}}>
            <FormControl sx={{ml:2,width:180}}>
              <InputLabel id="demo-simple-select-label2">Sort order</InputLabel>
              <Select
                labelId="demo-simple-select-label2"
                label="Sort order"
                value={sort.order}
                onChange={(e)=> setSort({...sort,order:e.target.value})}
              >
                {[
                  {v:'asc',n:"Ascending"},
                  {v:'desc',n:"Descending"},
                  ].map((opt,i) => (
                <MenuItem key={i} value={opt.v}>{opt.n}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <LoadingButton loading={state.loading} onClick={()=> getInitData()}
              sx={{ml:2}} variant='outlined' color="secondary">
                Apply changes
            </LoadingButton> 
            
          </Box>

          <Box sx={{ height: 600, width: '100%', mt:3 }}>
              <DataGrid
                rows={data.data}
                columns={[
                  {
                    field: 'name',
                    headerName: 'Name',
                    width: 220,
                    editable: false,
                    renderCell: (params) => (
                      < >
                        <Avatar alt={params.value} src={params.row.image_url} sx={{ mr: 2 }} />
                        <Typography variant="subtitle2" noWrap>
                          {params.row.first_name} {params.row.last_name}
                        </Typography>
                      </>
                    ),
                    valueGetter: (params) => `${params.row.first_name} ${params.row.last_name}`
                  },
                  {
                    field: 'email',
                    headerName: 'Email',
                    width: 180,
                    editable: false,
                  },
                  {
                    field: 'phone',
                    headerName: 'Phone',
                    width: 180,
                    editable: false,
                  },
                  {
                    field: 'created_at',
                    headerName: 'Joined at',
                    width: 180,
                    editable: false,
                    valueGetter: (params) => fDateTime(new Date(params.value)),
                  },
                ]} 

                paginationMode="server"
                rowCount={data.total_size}
                page={data.current_page - 1}
                rowsPerPage={data.data.length}
                onPageChange={(newPage)=> {
                  setSort({...sort, page:newPage + 1});
                  getInitData(newPage + 1);
                }}
                pageSize={data.page_size}
                onPageSizeChange={(newPage) => setSort({...sort, page:newPage})}
                rowsPerPageOptions={[]}
                
              />
            </Box>
      </Card>

    </Box>
  );

  
  async function getInitData() {
    // alert("here");
    if(data.data.length > 0){
      setData({...data,data:[]});
    }
    setState({...state,loading:true,loadComplete:false,error:null});

    try {
      
      const str = `?sort_order=${sort.order}&page=${state.page}&user_id=${user.user_id}`;

      const response = await axios.get(CRUD.user.referralList+str);

      if (isMountedRef.current) {
        setData(response.data);
      }
      setState({...state,loading:false,loadComplete:true,error:null})
    } catch (error) {
      // console.error(error); getErrorMessage(error)
      enqueueSnackbar(getErrorMessage(error));
      setState({...state,loading:false,loadComplete:false});
    }
  };
}
