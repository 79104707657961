/* eslint-disable import/order */
import PropTypes from 'prop-types';
// @mui
import { Card, Stack, Typography, Divider, Skeleton } from '@mui/material';
// utils
import { fNumber } from '../../../utils/formatNumber';

// ----------------------------------------------------------------------

ProfileStatInfo.propTypes = {
  wallet: PropTypes.object,
  loading: PropTypes.bool,
  pendingBalance: PropTypes.number,
};

export default function ProfileStatInfo({ wallet, loading,pendingBalance }) {

  return (
    <Card sx={{ py: 3 }}>
      <Stack direction="row" divider={<Divider orientation="vertical" flexItem />}>
        {loading && (
          <>
          <Skeleton sx={{width:120}} height={80} />
          <Skeleton sx={{width:120}} height={80} />
          </>
        )}
      </Stack>
      
      {!loading &&
      <Stack direction="row" divider={<Divider orientation="vertical" flexItem />}>
        <Stack width={1} textAlign="center">
          <Typography variant="h5">{wallet.currency}{fNumber(wallet.balance)}</Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            Wallet Balance
          </Typography>
        </Stack>

        <Stack width={1} textAlign="center">
          <Typography variant="h5"> {wallet.currency}{fNumber(wallet.ledger_balance)} </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            Ledger Balance
            </Typography>
        </Stack>
      </Stack>}

      {!loading &&
      <Stack sx={{mt:3}} direction="row" divider={<Divider orientation="vertical" flexItem />}>
        <Stack width={1} textAlign="center">
          <Typography variant="h5">{wallet.currency}{fNumber(pendingBalance)}</Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            Monthly Pending Bonus
          </Typography>
        </Stack>
      </Stack>}
    </Card>
  );

}
