/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, Box, Typography, Card, Stack, FormControlLabel, Switch, Skeleton } from '@mui/material';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css'
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import axios, { getErrorMessage } from 'utils/axios';
import { FormProvider, RHFTextField, RHFSelect } from 'components/hook-form';
import { LoadingButton } from '@mui/lab';
import CRUD from 'utils/Crud';
import { fData } from 'utils/formatNumber';
import useIsMountedRef from 'hooks/useIsMountedRef';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '2px solid whitesmoke',
    boxShadow: 24,
    p: 4,
};


const NewUserSchema = Yup.object().shape({
    amount: Yup.number("Enter a valid amount").required('Field is required').positive(),
    phone: Yup.string().required('Field is required'),
    coin_id: Yup.string().required('Field is required'),
    name: Yup.string(),
    transaction_type: Yup.string().required('Field is required'),
    wallet_address: Yup.string()
});

OrderCreateModal.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func,
    onContinue: PropTypes.func,
}

export default function OrderCreateModal({ open, handleClose,onContinue}) {

    const { enqueueSnackbar } = useSnackbar();
    const isMountedRef = useIsMountedRef();
    const [state,setState] = useState({});
    const [dataList,setDataList] = useState([]);


    const getInitData = useCallback(async () => {
    
        setState({...state,loading:true,loadComplete:false,error:null})
    
        try {
          const response = await axios.get(CRUD.cryptoAssets);
    
          if (isMountedRef.current) {
            setDataList(response.data);
          }
          setState({...state,loading:false,loadComplete:true,error:null})
        } catch (error) {
          // console.error(error);
          setState({...state,loading:false,loadComplete:false,error: getErrorMessage(error)})
        }
      }, [isMountedRef]);


    const methods = useForm({
        resolver: yupResolver(NewUserSchema),
        defaultValues:{
            // start_amount: range?.start_amount || 0,
            // end_amount: range?.end_amount || 0,
            // coin_id: range?.coin_id || '',
            // rate: range?.rate || '',
            // transaction_type: range?.transaction_type || ''
        },
    });

    useEffect(() => {
        getInitData();
      }, [getInitData]);

    const {
        reset,
        watch,
        control,
        setValue,
        handleSubmit,
        formState: { isSubmitting },
    } = methods;

    const values = watch();

    const onSubmit = async () => {
        try {
            const order = await axios.post(`${CRUD.cryptoRates}`,values);
            reset();
            enqueueSnackbar('Create success!');
            onContinue(order);
        } catch (error) {

        //   console.error(error);
            enqueueSnackbar(getErrorMessage(error), {variant:"error"});
        }
    };


    return <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        hideBackdrop={false}
    >
        <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2"  position="center" >
              Create order
            </Typography>

            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>

                <Card sx={{ p: 3, mt:4 }}>

                    <Box >
                        {state.loading && (
                            <Skeleton variant="rectangular" width="100%" height={48} />
                        )}
                        {state.loadComplete && (
                            <RHFSelect name="coin_id" label="Crypto" placeholder="Select option" required >
                                <option value="" />
                                {dataList.map((option) => (
                                <option key={option.coin_id} value={option.coin_id}>
                                    {option.name} ({option.coin_id})
                                </option>
                                ))}
                            </RHFSelect>
                            )}
                        
                        <RHFSelect name="transaction_type" label="Transaction type" sx={{mt:3}} placeholder="Select option" c >
                            <option value="" />
                            {['sell','buy'].map((option) => (
                            <option key={option} value={option}>
                                {option}
                            </option>
                            ))}
                        </RHFSelect>

                        {values.transaction_type === "buy" && <RHFTextField name="wallet_address" label="Wallet address" sx={{mt:3}} />}
                        
                        <RHFTextField name="amount" label="Amount ($)" sx={{mt:3}} />  
                        <RHFTextField name="request_id" label="Request ID or Phone number (+2348...)" sx={{mt:3}} />  
                        <RHFTextField name="name" label="User Name (optional)" sx={{mt:3}} />  

                        
                    </Box>
                    <br/>
                    <Stack alignItems="flex-end" sx={{ mt: 3 }}>
                        <LoadingButton type="submit" variant="contained" size='large' loading={isSubmitting}>
                        Create order
                        </LoadingButton>
                    </Stack>
                </Card>

            </FormProvider>
        </Box>
    </Modal>


}
