/* eslint-disable import/order */
/* eslint-disable camelcase */
import { sentenceCase } from 'change-case';
import { useState, useCallback, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import {
  Card,
  Table,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TableHead,
  Box,
  CircularProgress
} from '@mui/material';
// routes
import axios, { getErrorMessage } from 'utils/axios';
import useIsMountedRef from 'hooks/useIsMountedRef';
import CRUD from 'utils/Crud';
import { fDateTime } from 'utils/formatTime';
import AddAdminModal from 'sections/@dashboard/admin/AddAdminModal';
import { PATH_DASHBOARD } from '../../routes/paths';
// hooks
import useSettings from '../../hooks/useSettings';

// components
import Page from '../../components/Page';
import Label from '../../components/Label';
import Iconify from '../../components/Iconify';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
// sections
import { UserListHead, UserListToolbar, AdminMoreMenu } from '../../sections/@dashboard/admin';
import { LoadingButton } from '@mui/lab';
import AddAgentModal from 'sections/@dashboard/support-agent/AddAgentModal';
import Swal from 'sweetalert2'
import AppColors from "utils/AppColors";
import { handleSelectClick } from 'utils/commonFunc';
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'role', label: 'Role', alignRight: false },
  { id: 'created_at', label: 'Created at', alignRight: false },
];

// ----------------------------------------------------------------------

export default function SupportAgents() {
  const theme = useTheme();
  const { themeStretch } = useSettings();
  const isMountedRef = useIsMountedRef();
  const [modal, setModal] = useState({open:false, user:{}});
  const [userList, setUserList] = useState([]);
  const [selected, setSelected] = useState([]);
  const [state,setState] = useState({loading:false,loadComplete:false,error:null, isDeleting: false});


  const getAllAgents = useCallback(async () => {

    setState({...state,loading:true,loadComplete:false,error:null})

    try {
      const response = await axios.get(CRUD.supportAgent);

      if (isMountedRef.current) {
        setUserList(response.data);
      }
      setState({...state,loading:false,loadComplete:true,error:null})
    } catch (error) {
      // console.error(error);
      setState({...state,loading:false,loadComplete:false,error: getErrorMessage(error)})
    }
  }, [isMountedRef]);

  useEffect(() => {
    getAllAgents();
  }, [getAllAgents]);

  return (
    <Page title="Manage Admins:">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="Support Agents"
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            { name: 'Support agent' },
          ]}
          action={
            <Button
              variant="contained"
              component={Button}
              onClick={()=>{
                setModal({open:true, isEdit: false});
              }}
              startIcon={<Iconify icon={'eva:plus-fill'} />}
            >
              Add Agent
            </Button>
          }
        />

        {state.loading && (
          <Card>
             <Box padding={10} display={"flex"} justifyContent={"center"}>
              <CircularProgress />
            </Box>
          </Card>
        )}

        {!state.loading && state.error !=null && (
          <Card>
            <Box padding={10} display={"flex"} justifyContent={"center"}>
            <p>Error {state.error} </p>
            </Box>
          </Card>
        )}

        {state.loadComplete && <Card>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                
                <TableHead>
                  <TableRow>
                    <TableCell>{" "}</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Role</TableCell>
                    <TableCell>Chat Message</TableCell>
                    <TableCell>Created at</TableCell>
                    <TableCell>{" "}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {userList.map((row) => {
                    const { id, name, image_url,created_at, role,intro } = row;
                    const isItemSelected = selected.indexOf(id) !== -1;

                    return (
                      <TableRow
                        hover
                        key={id}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox checked={isItemSelected} onClick={()=> handleSelectClick(id, selected, setSelected)} />
                        </TableCell>
                        <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
                          <Avatar alt={name} src={image_url} sx={{ mr: 2 }} />
                          <Typography variant="subtitle2" noWrap>
                            {name}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">{role}</TableCell>
                        <TableCell align="left">{intro}</TableCell>
                        <TableCell align="left">{fDateTime(created_at)}</TableCell>

                        <TableCell align="right">
                          <LoadingButton 
                            type="submit" 
                            onClick={()=> removeUsers(row)}
                            variant="outlined" color="error"
                            loading={state.isDeleting}>
                            <Iconify icon={'eva:trash-2-outline'} sx={{  mr: 2, width: 20,height: 20,}} /> Delete
                          </LoadingButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {state.loadComplete && userList.length < 1 > 0 && (
                    <TableRow style={{ height: 53 * 3 }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {state.loadComplete && userList.length < 1 && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Box sx={{padding: 7}}>
                          <Typography>
                            Nothing here yet.
                          </Typography>
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

        </Card>}
      </Container>


     {modal.open &&  <AddAgentModal
        open={modal.open}
        handleClose={()=>{
          setModal({open:false});
        }}
        handleComplete={()=>{
          setModal({open:false});
          getAllAgents();
        }}
         />}

    </Page>
  );

  function removeUsers(agent){
    Swal.fire({
        text: "Do you want to remove this agent?",
        icon: 'warning',
        showCancelButton: true,
        // eslint-disable-next-line import/no-named-as-default-member
        confirmButtonColor: AppColors.DANGER,
        confirmButtonText: "Yes, contine",
        reverseButtons: true,
        showLoaderOnConfirm: true,
        allowOutsideClick: () => !Swal.isLoading(),
        preConfirm: async ()  =>  {
          try {
            await axios.delete(`${CRUD.supportAgent}/${agent.id}`);
            const data = userList.filter(e=>e.id !== agent.id);
            setUserList(data);
            return true;
          } catch (error) {
            Swal.showValidationMessage(
                `${getErrorMessage(error) || error.toString()}`
            );
            return false;
          }
        },
    }).then((result) => {
        if (result.value) {
            Swal.close();
        } 
    });

  }

}
